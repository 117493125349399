import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import { AuthContext } from "context/auth";
import { Search } from "components/atoms/search";
import {
    FilterIcon,
    UncheckedCheckbox,
    DeleteButton,
    DisabledCheckbox,
    CheckedCheckbox,
} from "assets/images";
import { ButtonWrapper, CancelButton, ButtonSpan } from "../RegisterTicket";
import CheckIcon from "components/atoms/icons/check.svg";
import TicketRequest from "http/ticket";
import { media } from "styles/media";
import StatusPopUp from "./StatusPopUp";
import DropdownMenu from "components/atoms/dropdownMenu";
const ticketRequest = new TicketRequest();

type ListProps = {
    onClose?: any;
    enrolledStudentList?: any;
    pickUpStationId: number | string;
    takeOffStationId: number | string;
    edit?: any;
    setEdit?: React.Dispatch<React.SetStateAction<any>>;
};

export function StudentList({
    onClose,
    enrolledStudentList,
    pickUpStationId,
    takeOffStationId,
    edit,
    setEdit,
}: ListProps) {
    const academyId = Number(localStorage.getItem("academyId"));
    const [data, setData] = useState<string[] | any[]>([]);
    const [searchResult, setSearchResult] = useState<string[] | any[]>();
    const [selectedStudent, setSelectedStudent] = useState([]);
    const [allSelect, setAllSelect] = useState<boolean>(false);
    const [addStudentPopUp, setAddStudentPopUp] = useState(false);
    const [searchKeyword, setSearchKeyword] = useState();
    const [showGradeFilter, setShowGradeFilter] = useState(false);
    const [gradeList, setGradeList] = useState([]);
    const [selectedGrade, setSelectedGrade] = useState<undefined | any>();
    const [addCompletePopUp, setAddCompletePopUp] = useState<boolean>(false);

    useEffect(() => {
        getStudentList();
    }, [academyId]);

    const getStudentList = async () => {
        const params = { academyId: academyId };
        await ticketRequest.getStudentList(params, (res: any) => {
            if (res.status === 200) {
                setData(res?.data?.data);
            } else {
                alert(`[${res.data.code}] ${res.data.message}`);
            }
        });
    };

    // 이미 등록되어있는 학생 ID
    const keyToExtract = "id";
    const [enrolledId, setEnrolledId] = useState(
        enrolledStudentList?.map((item) => item[keyToExtract])
    );

    useEffect(() => {
        const editStudentsId = edit?.map((el: any) => el?.id);
        setEnrolledId((prev) => [...prev, ...editStudentsId]);
    }, [edit]);

    //리스트에서 학생 선택
    const handleClick = (items: any) => {
        const newStudent = items;
        const selectedStudentList = [...selectedStudent, newStudent];
        setSelectedStudent(selectedStudentList);
    };

    // 선택한 리스트에서 학생 제거
    const deleteStudent = (deleteValue: any) => {
        const deleteStudentList = selectedStudent.filter(
            (item) => item !== deleteValue
        );
        setSelectedStudent(deleteStudentList);
    };

    // 전체 선택 체크 여부
    const handleAllSelect = () => {
        setAllSelect(!allSelect);
        if (!allSelect) {
            const removeDuplicate = data.filter(
                (obj: any) => !enrolledId.includes(obj?.id)
            );
            setSelectedStudent(removeDuplicate);
            if (selectedGrade !== undefined) {
                const gradeFiltered = removeDuplicate.filter((item: any) =>
                    item?.nickname?.includes(selectedGrade.value)
                );
                setSelectedStudent(gradeFiltered);
            }
        } else {
            setSelectedStudent([]);
        }
    };

    // 선택한 학생 데이터 형식으로 Mapping
    const dataStudentParams = selectedStudent?.map((obj: any) => ({
        id: obj.id,
        name: obj.name,
        nickName: obj.nickName,
        parentPhone: obj.parentPhone,
        pickUpStationId,
        takeOffStationId,
        tickets: "",
    }));

    // 적용 버튼 클릭
    const handleApplyButton = () => {
        setEdit((prev) => [...prev, ...dataStudentParams]);
        setAddStudentPopUp(false);
        setAddCompletePopUp(true);
    };

    const handleConfirmButton = () => {
        onClose();
        setAddCompletePopUp(false);
    };

    useEffect(() => {
        // 학년 리스트
        const currentGradeList = data?.map((el: any, idx: number) => ({
            id: idx,
            value: el?.nickName,
        }));

        // 학년 중복제거
        const reduceDuplicationGrade = currentGradeList.filter(
            (grade: any, i: number) => {
                return (
                    currentGradeList.findIndex((grade2: any, j: number) => {
                        return grade.value === grade2.value;
                    }) === i
                );
            }
        );

        // '' or undefined 삭제
        const reduceEmptyValue = reduceDuplicationGrade.filter(
            (item: any) =>
                item?.value !== undefined &&
                item?.value !== "" &&
                item?.value !== "-"
        );

        setGradeList(reduceEmptyValue);
    }, [data]);

    // 키워드 검색
    useEffect(() => {
        let filteredData = [...data];

        if (searchKeyword) {
            const nameFiltered = data.filter((item: any) =>
                item.name.includes(searchKeyword)
            );

            const numberFiltered = data.filter((item: any) =>
                item.parentPhone.slice(-4).includes(searchKeyword)
            );

            filteredData = [...nameFiltered, ...numberFiltered];
        }

        if (selectedGrade?.value) {
            filteredData = filteredData.filter((item) =>
                item.nickName.includes(selectedGrade.value)
            );
        }

        setSearchResult(filteredData);
    }, [searchKeyword, selectedGrade, data]);

    const handleDropdownMenu = (data: any) => {
        setSelectedGrade(data);
    };

    useEffect(() => {
        setAllSelect(false);
    }, [selectedGrade]);

    return (
        <>
            <Container>
                <SearchWrapper>
                    <div style={{ minWidth: "426px" }}>
                        <Search setSearchKeyword={setSearchKeyword} />
                    </div>
                    <GradeFilter
                        onMouseEnter={async (e) =>
                            await setShowGradeFilter(true)
                        }
                        onMouseLeave={async (e) =>
                            await setShowGradeFilter(false)
                        }
                    >
                        <GradeImg />
                        <GradeSpan>
                            {selectedGrade === undefined
                                ? "학년 정보"
                                : `${selectedGrade.value}`}
                        </GradeSpan>

                        {showGradeFilter && (
                            <DropdownMenuContainer
                                aria-label={"dropdown-ul"}
                                showGradeFilter={showGradeFilter}
                            >
                                <CheckImg
                                    onClick={() => {
                                        setSearchResult(data);
                                        setSelectedGrade(undefined);
                                    }}
                                >
                                    <CheckedWrap>
                                        {selectedGrade?.value === undefined && (
                                            <img src={CheckIcon} alt="" />
                                        )}
                                    </CheckedWrap>
                                    <TotalButton key="-1">전체</TotalButton>
                                </CheckImg>

                                {gradeList?.map((item: any) => {
                                    return (
                                        <DropdownMenu
                                            key={item?.id}
                                            isSelected={
                                                item?.id === selectedGrade?.id
                                            }
                                            onClick={() =>
                                                handleDropdownMenu(item)
                                            }
                                            checked={selectedGrade?.value}
                                        >
                                            {item?.value}
                                        </DropdownMenu>
                                    );
                                })}
                            </DropdownMenuContainer>
                        )}
                    </GradeFilter>
                </SearchWrapper>

                <Grid>
                    <LeftContainer>
                        <Notice>
                            *이미 동시간대 승차권이 등록되어 있는 학생은 선택할
                            수 없습니다.
                        </Notice>
                        <TableBox>
                            <table>
                                <thead>
                                    <tr>
                                        <th onClick={() => handleAllSelect()}>
                                            <img
                                                src={
                                                    allSelect
                                                        ? CheckedCheckbox
                                                        : UncheckedCheckbox
                                                }
                                                alt=""
                                                style={{
                                                    width: "18px",
                                                    height: "18px",
                                                    cursor: "pointer",
                                                    transform:
                                                        "translateY(2px)",
                                                }}
                                            />{" "}
                                        </th>
                                        <th
                                            style={{ justifyContent: "center" }}
                                        >
                                            <div
                                                style={{
                                                    lineHeight: "16px",
                                                    transform:
                                                        "translateY(-2px)",
                                                }}
                                            >
                                                학년
                                            </div>
                                        </th>
                                        <th>
                                            <div
                                                style={{
                                                    lineHeight: "16px",
                                                    transform:
                                                        "translateY(-2px)",
                                                }}
                                            >
                                                학생이름
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {searchResult?.map((item: any) => (
                                        <tr
                                            key={item?.id}
                                            className={
                                                enrolledId.includes(item?.id)
                                                    ? "disabled"
                                                    : selectedStudent.includes(
                                                          item
                                                      )
                                                    ? "selected"
                                                    : ""
                                            }
                                            onClick={
                                                enrolledId.includes(item.id)
                                                    ? () => {}
                                                    : selectedStudent.includes(
                                                          item
                                                      )
                                                    ? () => deleteStudent(item)
                                                    : () => handleClick(item)
                                            }
                                        >
                                            {enrolledId.includes(item.id) ? (
                                                <td>
                                                    <img
                                                        src={DisabledCheckbox}
                                                        alt=""
                                                        style={{
                                                            width: "18px",
                                                            height: "18px",
                                                            transform:
                                                                "translateY(2px)",
                                                        }}
                                                    />
                                                </td>
                                            ) : selectedStudent.includes(
                                                  item
                                              ) ? (
                                                <td>
                                                    <img
                                                        src={CheckedCheckbox}
                                                        alt=""
                                                        style={{
                                                            width: "18px",
                                                            height: "18px",
                                                            transform:
                                                                "translateY(2px)",
                                                        }}
                                                    />
                                                </td>
                                            ) : (
                                                <td>
                                                    <img
                                                        src={UncheckedCheckbox}
                                                        alt=""
                                                        style={{
                                                            width: "18px",
                                                            height: "18px",
                                                            transform:
                                                                "translateY(2px)",
                                                        }}
                                                    />
                                                </td>
                                            )}
                                            <td>
                                                {" "}
                                                <div
                                                    style={{
                                                        lineHeight: "16px",
                                                        transform:
                                                            "translateY(-2px)",
                                                    }}
                                                >
                                                    {item.nickName}
                                                </div>
                                            </td>
                                            <td>
                                                {" "}
                                                <div
                                                    style={{
                                                        lineHeight: "16px",
                                                        transform:
                                                            "translateY(-2px)",
                                                    }}
                                                >
                                                    {`${
                                                        item.name
                                                    }(${item.parentPhone.slice(
                                                        -4
                                                    )})`}
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </TableBox>
                    </LeftContainer>
                    <RightContainer>
                        {selectedStudent?.length > 0 ? (
                            <StudentCount>
                                <Span>
                                    총&nbsp;
                                    <span style={{ fontWeight: "800" }}>
                                        {selectedStudent?.length}명
                                    </span>
                                    &nbsp;선택
                                </Span>
                                <Span
                                    onClick={() => setSelectedStudent([])}
                                    style={{
                                        textDecoration: "underline",
                                        cursor: "pointer",
                                        marginLeft: "72px",
                                    }}
                                >
                                    선택 초기화
                                </Span>
                            </StudentCount>
                        ) : null}
                        <StudentWrapper>
                            {selectedStudent?.map((item) => {
                                return (
                                    <div key={item.id}>
                                        <StudentEl>
                                            <StudentSpan>
                                                {item?.nickName === "" ||
                                                item?.nickName === "-"
                                                    ? ""
                                                    : `${item?.nickName} / `}
                                                {`${
                                                    item?.name
                                                }(${item.parentPhone.slice(
                                                    -4
                                                )})`}
                                            </StudentSpan>
                                            <Delete
                                                onClick={() =>
                                                    deleteStudent(item)
                                                }
                                            />
                                        </StudentEl>
                                    </div>
                                );
                            })}
                        </StudentWrapper>
                    </RightContainer>
                </Grid>

                <ButtonWrapper
                    style={{
                        borderTop: "1px solid #EAEAEA",
                        borderRadius: "0px 0px 12px 12px",
                    }}
                >
                    <CancelButton
                        onClick={() => {
                            onClose(false);
                        }}
                    >
                        <ButtonSpan style={{ fontWeight: 700 }}>
                            취소
                        </ButtonSpan>
                    </CancelButton>
                    <ApplyButton
                        onClick={() => setAddStudentPopUp(true)}
                        style={{ backgroundColor: "#FFD100" }}
                    >
                        <ButtonSpan
                            style={{ color: "#332A00", fontWeight: 700 }}
                        >
                            저장
                        </ButtonSpan>
                    </ApplyButton>
                </ButtonWrapper>
            </Container>
            {addStudentPopUp && (
                <StatusPopUp
                    title={"학생 추가"}
                    contents={"학생을 추가하시겠습니까?"}
                    width={"360px"}
                    rightButton={"추가"}
                    height={"200px"}
                    handler={setAddStudentPopUp}
                    confirm={handleApplyButton}
                />
            )}
            {addCompletePopUp && (
                <StatusPopUp
                    title={"학생 추가"}
                    contents={"학생이 추가되었습니다."}
                    rightButton={"확인"}
                    width={"360px"}
                    height={"200px"}
                    handler={setAddCompletePopUp}
                    type="single"
                    confirm={handleConfirmButton}
                />
            )}
        </>
    );
}

const Container = styled.div`
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 12px;
`;

const SearchWrapper = styled.div`
    height: 74px;
    padding: 20px 24px;
    background-color: #f7f7f7;
    border-radius: 12px 12px 0px 0px;
    display: flex;
    align-items: center;
`;

const GradeFilter = styled.button`
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 8px;
    background-color: #a3a3a3;
    width: 93px;
    height: 34px;
    border: none;
    padding: 8px 13px 8px 11px;
    margin-left: 8px;
    justify-content: center;
    :hover {
        cursor: pointer;
    }
`;

const ApplyButton = styled.button`
    width: 70px;
    height: 36px;
    border-radius: 30px;
    background-color: #ffcd00;
    border: 1px solid #fff1b3;
`;

const GradeImg = styled.div`
    background: url(${FilterIcon}) center center no-repeat;
    width: 18px;
    height: 18px;
`;

const GradeSpan = styled.span`
    color: #fff;
    font-size: 12px;
    line-height: 14px;
    font-weight: 700;
    font-family: NanumSquareRound;
`;

const Grid = styled.div`
    padding: 20px;
    display: grid;
    grid-template-columns: 303px 212px;
    column-gap: 20px;
`;

const LeftContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const Notice = styled.span`
    color: #585858;
    font-size: 10px;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 8px;
`;

const RightContainer = styled.div`
    border-radius: 8px;
    border: 1px solid #dfdfdf;
    background: #fff;
    min-height: 420px;
    max-height: 420px;
    padding: 16px;
`;

const StudentCount = styled.div`
    display: flex;
    position: fixed;
    justify-content: space-between;
`;

const Span = styled.span`
    color: #585858;
    font-size: 11px;
    font-weight: 400;
    line-height: 16px;
`;

const StudentWrapper = styled.div`
    height: 100%;
    margin-top: 16px;
    overflow: scroll;
    width: 100%;
    ::-webkit-scrollbar {
        display: none; /* 크롬, 사파리, 오페라, 엣지 */
    }
`;

const StudentEl = styled.div`
    border-radius: 4px;
    border: 1px solid rgba(208, 208, 208, 0.6);
    background: #fff;
    margin: 8px 0px;
    position: relative;
    padding: 8px;
`;

const StudentSpan = styled.span`
    color: #000;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: -0.36px;
`;

const Delete = styled.div`
    position: absolute;
    top: 7px;
    right: 8px;
    background: url(${DeleteButton}) center no-repeat;
    width: 14px;
    height: 14px;
    cursor: pointer;
`;

const TableBox = styled.div`
    overflow: visible;
    width: 303px;
    overflow: scroll;
    max-height: 400px;

    ${media.tablet} {
        max-height: 400px;
        overflow-x: visible;
        overflow-y: auto;
    }
    ${media.desktop} {
        max-height: 400px;
    }
    table {
        width: 100%;
        /* border-spacing: 0;
        min-width: 550px; */

        ::-webkit-scrollbar {
            display: none; /* 크롬, 사파리, 오페라, 엣지 */
        }
        ${media.tablet} {
            min-width: auto;
        }
        .disabled {
            color: #bbb;
            :hover {
                cursor: default;
                background-color: #fff;
            }
        }
        .selected {
            background-color: #fffae5;
        }

        thead {
            tr {
                max-height: 42px;
                color: ${({ theme }) => theme.colors.blue};
                font-weight: 800;
                border-top: 1px solid rgba(208, 208, 208, 0.6);
                border-bottom: 1px solid rgba(208, 208, 208, 0.6);
                th {
                    background-color: white;
                    box-shadow: 0px 1px 0px 0 rgba(0, 0, 0, 0.1);
                    color: #174490;
                    padding: 9.8px;
                    text-align: center;
                }
            }
        }
        tbody {
            tr {
                line-height: 14px;
                :hover {
                    cursor: pointer;
                    border-radius: 10px;
                    -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
                    -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
                    background-color: #fffae5;
                }
            }
        }

        td {
            text-align: center;
            margin: auto 0;
            /* padding: 20px 10px; */
            border-bottom: 1px solid #e1e1e1;
            /* justify-content: center; */
            /* line-height: 10px; */
            /* :last-child {
                border-right: 0;
            } */
            ${media.desktop} {
                padding: 12px 16px;
            }
        }
    }
`;

type DropDownMenuProps = {
    showGradeFilter?: boolean;
};
const DropdownMenuContainer = styled.ul<DropDownMenuProps>`
    z-index: 12222;
    position: absolute;
    top: 0px;
    right: 0px;
    width: 146px;
    overflow: scroll;
    background-color: #fff;
    padding: 4px 0px;
    margin-top: 2px;
    border-radius: 12px;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.2);

    & > li:not(:last-child) {
        margin-bottom: 8px;
    }

    @keyframes fade-in {
        to {
            max-height: 100vh;
        }
    }
    @keyframes fade-out {
        to {
            opacity: 0;
        }
    }

    animation: ${({ showGradeFilter }) =>
        showGradeFilter ? "fade-in 0.4s ease-in-out" : "fade-out 0.4s ease"};
    animation-fill-mode: forwards;

    ::-webkit-scrollbar {
        display: none; /* 크롬, 사파리, 오페라, 엣지 */
    }
`;

const CheckImg = styled.div`
    margin-top: 4px;
    display: flex;
    align-items: center;
    height: 32px;
    padding: 8px 20px 8px 4px;
    :hover {
        background-color: #f7f7f7;
        cursor: pointer;
    }
`;

const CheckedWrap = styled.div`
    flex-shrink: 0;
    width: 12px;
    height: 12px;
    margin-right: 6px;
`;

const TotalButton = styled.div`
    font-weight: 400;
    font-size: 12px;
    color: #1e2939;
    font-family: NanumSquareRound;
`;
