import React, { Fragment, useContext, useEffect, useState } from "react";
import { BoxHeader } from "styles/layout";
import styled, { css } from "styled-components";
import { StudentScheduleList } from "./StudentScheduleList";
import {
    weekdayOfFirst,
    weekdayOfLast,
    calcWeekday,
    weekdayOfFirstFrom,
    compareToday,
} from "../DateUtil";
import ReactTooltip from "react-tooltip";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendarIcon from "../../assets/images/icons/calendar_icon.svg";
import dayjs from "dayjs";
import ko from "date-fns/locale/ko";
import { SavePopUp } from "./CheckPopUp";
import { PopUp2 } from "../layout/PopUp2";
import { ScheduleCheck } from "./ScheduleCheck";
import { ScheduleServiceContext } from "../../context/scheduleService";
import StudentRequest from "../../http/student";
import { GlobalContext } from "../../context/global";

registerLocale("ko", ko);

const studentRequest = new StudentRequest();

interface StudentScheduleProps {
    studentId: number;
    studentName: any;
}

interface OptionVisibleProps {
    allHidden: boolean | false;
    labelShow?: boolean | undefined;
}

interface WeekdayProps {
    visible?: boolean | undefined;
}

interface BtnEditableProps {
    editable?: boolean | undefined;
}

const BoxBody = styled.div`
    display: table;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 15px;
`;

const EditHelp = styled.div<WeekdayProps>`
    ${({ visible }) =>
        visible
            ? css`
                  display: none;
              `
            : css`
                  display: table;
              `}
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 15px;
    padding: 20px;
    background: rgba(23, 68, 144, 0.04);
    border: 1px solid #e7e7e7;
    box-sizing: border-box;
    border-radius: 8px;
    font-family: NanumSquareRound;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
`;

const Radio = styled.div`
    display: inline-flex;
    height: 40px;
    vertical-align: middle;
    padding: 9px 0;
`;

const Option = styled.label<OptionVisibleProps>`
    ${({ allHidden }) =>
        !allHidden
            ? css`
                  display: inline-flex;
              `
            : css`
                  display: none;
              `}
    font-family: NanumSquareRound;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    margin: 0;
    margin-right: 20px;
    cursor: pointer;
    input[type="radio"] {
        width: 16px;
        height: 16px;
        background: #ffffff;
        border: 1px solid #cccccc;
        box-sizing: border-box;
        border-radius: 12px;
        margin: 0;
        margin-right: 5px;
        ${({ labelShow }) =>
            labelShow
                ? css`
                      display: none;
                  `
                : css``}
    }
    input[type="radio"]:checked {
        border: 5px solid #ffcd00;
        box-sizing: border-box;
        border-radius: 12px;
    }
    span {
        background: #333333;
        margin-left: 5px;
        width: 16px;
        height: 16px;
        color: #fff;
        text-align: center;
        font-size: 10px;
        font-weight: bold;
        border-radius: 12px;
        ${({ labelShow }) =>
            labelShow
                ? css`
                      display: none;
                  `
                : css``}
    }
`;

const DatePickerDiv = styled.div<WeekdayProps>`
    ${({ visible }) =>
        visible
            ? css`
                  display: inline-flex;
              `
            : css`
                  display: none;
              `}
    position: relative;
    border: 1px solid #e1e1e1;
    border-radius: 4px;
    width: 170px;
    padding-left: 34px;
    padding: 8px 0 8px 34px;
    background: url(${CalendarIcon}) left 10px center no-repeat;
    input {
        border: none;
        border-left: 1px solid #e1e1e1;
        font-size: 14px;
        font-weight: 600;
        width: 120px;
        height: 30px;
        padding: 0 5px;
        height: 14px;
        background: #fff !important;
    }
`;

const FixDate = styled.div<WeekdayProps>`
    ${({ visible }) =>
        visible
            ? css`
                  display: inline-block;
              `
            : css`
                  display: none;
              `}

    position: relative;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    margin: 0;
`;

export const Weekday = styled.div<WeekdayProps>`
    ${({ visible }) =>
        visible
            ? css`
                  display: inline-block;
              `
            : css`
                  display: none;
              `}

    width: 100%;
    height: 64px;
    line-height: 64px;
    text-align: center;
    border-top: 1px solid #e1e1e1;
    .weekday {
        font-family: NanumSquareRound;
        font-style: normal;
        font-weight: 800;
        font-size: 16px;
        line-height: 18px;
        vertical-align: middle;
        margin: 0 12px;
    }
`;

const Arrow = styled.span`
    width: 8px;
    height: 8px;
    display: inline-block;
    position: absolute;
    top: 7px;
    left: 7px;
    span {
        width: 7px;
        height: 2px;
        position: absolute;
        background-color: #000;
        display: inline-block;
        border-radius: 8px;
        transition: all 0.2s ease;
        &:first-of-type {
            top: 1px;
            left: 0;
            transform: rotate(-45deg);
        }
        &:last-of-type {
            top: 5px;
            left: 0;
            transform: rotate(45deg);
        }
    }
`;

const ButtonPrev = styled.button`
    display: inline-block;
    position: relative;
    width: 24px;
    height: 24px;
    vertical-align: middle;
    padding: 0;
    background: #ffffff;
    border: 1px solid #bababa;
    box-sizing: border-box;
    border-radius: 12px;
`;
const ButtonNext = styled.button`
    display: inline-block;
    position: relative;
    width: 24px;
    height: 24px;
    vertical-align: middle;
    padding: 0;
    background: #ffffff;
    border: 1px solid #bababa;
    box-sizing: border-box;
    border-radius: 12px;
`;

const ButtonDiv = styled.div<WeekdayProps>`
    ${({ visible }) =>
        visible
            ? css`
                  display: block;
              `
            : css`
                  display: none !important;
              `}
    vertical-align: middle;
    text-align: center;
    padding: 0;
    margin: 0 auto;
    display: inline-block;
    float: right;

    button {
        width: 75px;
        vertical-align: middle;
        padding: 10px 22px;
        box-sizing: border-box;
        border-radius: 20px;
        font-family: NanumSquareRound;
        font-style: normal;
        font-weight: 800;
        font-size: 14px;
        line-height: 16px;
        margin: 2px;
    }
`;

const ButtonYellow = styled.button<BtnEditableProps>`
    ${({ editable }) =>
        editable
            ? css``
            : css`
                  display: none;
              `}
    background: #FFCD00;
    border: 1px solid #ffcd00;
`;

const ButtonWhite = styled.button<BtnEditableProps>`
    ${({ editable }) =>
        editable
            ? css``
            : css`
                  display: none;
              `}
    background: #EFEFEF;
    border: 1px solid #efefef;
`;

export const StudentSchedule: React.FC<StudentScheduleProps> = ({
    studentId,
    studentName,
}) => {
    const { setToast } = useContext(GlobalContext);
    const [scheduleList, setScheduleList] = useState([]);
    const [weekdaySchedule, setWeekdaySchedule] = useState(true);
    const [editSchedule, setEditSchedule] = useState(false);
    const [emptyData, setEmptyData] = useState(true);
    const [refresh, setRefresh] = useState(false);
    const [startDate, setStartDate] = useState(weekdayOfFirst());
    const [endDate, setEndDate] = useState(weekdayOfLast());
    const [confirmPopUp, setConfirmPopUp] = useState<boolean>(false);
    const [popUpCancel, setPopUpCancel] = useState(false);
    const [popUpBtnCancel, setPopUpBtnCancel] = useState(false);
    const [confirmCancel, setConfirmCancel] = useState<boolean>(false);
    const [buttonStatus, setButtonStatus] = useState<string>("");
    const [rawDate, setRawDate] = useState(new Date());
    const formattedDate = dayjs(rawDate).format("YYYY.MM.DD");
    const fixedDate = dayjs(rawDate).format("YYYY.MM.DD");
    const [buttonCheck, setButtonCheck] = useState<boolean>(false);

    const handleListChange = () => {
        setStartDate("");
        setWeekdaySchedule(!weekdaySchedule);
    };

    const handlePopUp = (cancel: boolean) => {
        setPopUpBtnCancel(cancel);
        setConfirmPopUp(true);
    };

    useEffect(() => {
        if (weekdaySchedule) {
            setStartDate(weekdayOfFirstFrom(endDate));
        } else {
            setStartDate(formattedDate);
        }

        console.log("empty : " + emptyData);
    }, [
        weekdaySchedule,
        rawDate,
        startDate,
        endDate,
        formattedDate,
        emptyData,
    ]);

    useEffect(() => {
        if (!confirmPopUp && !popUpCancel && buttonCheck) {
            console.log("schedule changed");

            let data: any = [];

            scheduleList.forEach((schedule: any) => {
                let changeData: any = {};
                let check: boolean = false;

                if (
                    schedule.changeCode !== undefined &&
                    schedule.changeCode !== "" &&
                    !schedule.seatFull
                ) {
                    changeData.scheduleId = schedule.scheduleId;
                    changeData.dispatchCode = schedule.changeCode;
                    changeData.boardSwitch = schedule.boardSwitch;
                    check = true;
                } else {
                    changeData.dispatchCode = schedule.dispatchCode;
                }

                if (
                    schedule.changeBoardSwitch !== undefined &&
                    schedule.changeBoardSwitch !== "" &&
                    !schedule.seatFull
                ) {
                    changeData.scheduleId = schedule.scheduleId;
                    changeData.boardSwitch = schedule.changeBoardSwitch;

                    check = true;
                }

                if (check) {
                    data.push(changeData);
                }
            });

            if (data.length > 0) {
                studentRequest.updateSchedules(
                    studentId,
                    data,
                    (response: any) => {
                        console.log(response);
                        if (
                            response.status === -1 ||
                            (response.status === 200 &&
                                response.data.code !== "OK")
                        ) {
                            console.log(
                                `[${response.data.code}] ${response.data.message}`
                            );

                            // 수정 가능한 시간 제외 오류내용 기재X(22.3.22)
                            if (response.data.code === "R801") {
                                setToast({
                                    toggle: true,
                                    toastStatus: "fail",
                                    message: `${response.data.message}`,
                                });
                            } else {
                                setToast({
                                    toggle: true,
                                    toastStatus: "fail",
                                    message: `오류가 발생했습니다.`,
                                });
                            }
                        } else {
                            setEditSchedule(false);
                            setRefresh(!refresh);
                            setToast({
                                toggle: true,
                                toastStatus: "success",
                                message: `저장되었습니다.`,
                            });
                        }
                    },
                    (error: any) => {
                        console.log(error);
                    }
                );
            } else {
                setEditSchedule(false);
            }
        }
        setButtonCheck(false); // 저장 버튼 누른 이벤트 초기화
        console.log("schedule not changed");
    }, [confirmPopUp, popUpCancel]);

    return (
        <ScheduleServiceContext.Provider
            value={{
                studentId,
                studentName,
                scheduleList,
                setScheduleList,
            }}
        >
            <Fragment>
                <BoxHeader>
                    <h2>등하원 스케쥴</h2>
                </BoxHeader>
                <BoxBody>
                    <Radio>
                        <Option allHidden={editSchedule}>
                            <input
                                type="radio"
                                name="weekday"
                                onChange={handleListChange}
                                checked={weekdaySchedule}
                            />
                            스케줄 보기
                        </Option>
                        <Option allHidden={editSchedule}>
                            <input
                                type="radio"
                                name="weekday"
                                onChange={handleListChange}
                                checked={!weekdaySchedule}
                            />
                            당일 스케줄 변경하기
                            <span
                                data-tip="선택한 날짜의 등하원 스케줄을<br />변경할 수 있습니다."
                                data-for="select-date"
                            >
                                ?
                            </span>
                        </Option>
                        <ReactTooltip
                            id="select-date"
                            place={"bottom"}
                            html={true}
                        />
                    </Radio>
                    <DatePickerDiv visible={!weekdaySchedule && !editSchedule}>
                        <DatePicker
                            selected={rawDate}
                            onChange={(rawDate: Date) => {
                                setStartDate(formattedDate);
                                setRawDate(rawDate);
                            }}
                            locale={"ko"}
                            dateFormat="yyyy-MM-dd (EEE)"
                            placeholderText="운행일을 선택하세요"
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            disabled={editSchedule}
                        />
                    </DatePickerDiv>
                    <FixDate visible={editSchedule}>
                        선택한 날짜 : {fixedDate}
                    </FixDate>
                    <ButtonDiv visible={!weekdaySchedule}>
                        <ButtonYellow
                            editable={
                                !editSchedule &&
                                !emptyData &&
                                compareToday(startDate)
                            }
                            onClick={() => {
                                setEditSchedule(true);
                            }}
                        >
                            수정
                        </ButtonYellow>
                        <ButtonWhite
                            editable={editSchedule}
                            onClick={() => {
                                handlePopUp(true);
                                setButtonStatus("c");
                            }}
                        >
                            취소
                        </ButtonWhite>
                        <ButtonYellow
                            editable={editSchedule}
                            onClick={() => {
                                handlePopUp(false);
                                setButtonStatus("s");
                            }}
                        >
                            저장
                        </ButtonYellow>
                    </ButtonDiv>
                </BoxBody>
                <EditHelp visible={weekdaySchedule}>
                    <span style={{ color: "#FF7900" }}>
                        - 수정은 첫 정류장 출발 시간 30분 전까지만 가능합니다.
                    </span>
                    <br />
                    - 타요/안타요 설정과 등하원 시간 변경 두 가지 수정이
                    가능합니다. (정류장 변경은 [버스 운행 관제] 메뉴에서
                    가능합니다.)
                    <br />
                    - 변경되는 등하원 시간에 맞춰 호차는 변경될 수 있습니다.
                    <br />
                </EditHelp>
                <BoxBody>
                    <Weekday visible={weekdaySchedule}>
                        <ButtonPrev
                            onClick={() => {
                                setStartDate(calcWeekday(startDate, false));
                                setEndDate(calcWeekday(endDate, false));
                            }}
                        >
                            <Arrow>
                                <span />
                                <span />
                            </Arrow>
                        </ButtonPrev>
                        <span className={"weekday"}>
                            {startDate} ~ {endDate}
                        </span>
                        <ButtonNext
                            onClick={() => {
                                setStartDate(calcWeekday(startDate, true));
                                setEndDate(calcWeekday(endDate, true));
                            }}
                        >
                            <Arrow
                                style={{
                                    margin: "0",
                                    transform: "rotate(180deg)",
                                }}
                            >
                                <span />
                                <span />
                            </Arrow>
                        </ButtonNext>
                    </Weekday>
                    <StudentScheduleList
                        weekdaySchedule={weekdaySchedule}
                        studentId={studentId}
                        selectedDate={startDate}
                        editSchedule={editSchedule}
                        setEmpty={setEmptyData}
                        refresh={refresh}
                    />
                </BoxBody>
                {
                    // 취소
                    buttonStatus === "c" && confirmPopUp ? (
                        <PopUp2
                            open={confirmPopUp}
                            handler={setConfirmPopUp}
                            contents={
                                <ScheduleCheck
                                    handler={setConfirmPopUp}
                                    cancel={setPopUpCancel}
                                    btnCancel={popUpBtnCancel} // true
                                    formattedDate={formattedDate}
                                    buttonStatus={buttonStatus}
                                    setModify={setConfirmCancel}
                                    buttonHandler={setButtonCheck}
                                />
                            }
                        />
                    ) : (
                        buttonStatus === "s" &&
                        confirmPopUp && (
                            <SavePopUp
                                open={confirmPopUp}
                                handler={setConfirmPopUp}
                                contents={
                                    <ScheduleCheck
                                        handler={setConfirmPopUp}
                                        cancel={setPopUpCancel}
                                        btnCancel={popUpBtnCancel}
                                        formattedDate={formattedDate}
                                        buttonStatus={buttonStatus}
                                        setModify={setConfirmCancel}
                                        buttonHandler={setButtonCheck}
                                    />
                                }
                            />
                        )
                    )
                }
            </Fragment>
        </ScheduleServiceContext.Provider>
    );
};
