import React, { useRef } from "react";
import styled from "styled-components";
import moment from "moment";
import DatePicker from "react-datepicker";
import CalendarLeftArrow from "assets/images/icons/calendar_left_arrow.svg";
import CalendarRightArrow from "assets/images/icons/calendar_right_arrow.svg";
import { Icon } from "styles/ui";
import { Calendar, CalendarDisabledLeft } from "assets/images";
import "./singleDatePicker.css";

type DatePickerProps = {
    startDate: any;
    dateChange?: (dates) => void;
};

function SingleDatePicker(props: DatePickerProps) {
    const { startDate, dateChange } = props;
    const calRef = useRef<any>();
    const childRef = useRef();

    return (
        <DatePicker
            locale="ko"
            startDate={startDate}
            selected={startDate}
            ref={calRef}
            onChange={dateChange}
            minDate={startDate}
            showPopperArrow={false}
            dateFormat="yyyy-MM-dd"
            className="singleDatePicker"
            customInput={<CustomInput ref={childRef} />}
            popperPlacement="top-end"
            renderCustomHeader={({
                date,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
            }) => {
                return (
                    <>
                        <div>
                            <div
                                style={{
                                    width: "224px",
                                    height: "1px",
                                    backgroundColor: "#f0f0f0",
                                    margin: "0 auto",
                                }}
                            />
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    textAlign: "left",
                                    padding: 16,
                                    maxHeight: "52px",
                                }}
                            >
                                <ChangeMonthButton
                                    disabled={prevMonthButtonDisabled}
                                    onClick={decreaseMonth}
                                    style={{ marginRight: 8 }}
                                >
                                    <Icon
                                        img={
                                            moment(date).format("YYYY-MM") ===
                                            moment(new Date()).format("YYYY-MM")
                                                ? CalendarDisabledLeft
                                                : CalendarLeftArrow
                                        }
                                        width={"100%"}
                                        height={"100%"}
                                    ></Icon>
                                </ChangeMonthButton>
                                <CurrentMonth>
                                    {moment(date).format("YYYY")}년{" "}
                                    {moment(date).format("MM")}월
                                </CurrentMonth>
                                <ChangeMonthButton
                                    disabled={nextMonthButtonDisabled}
                                    onClick={increaseMonth}
                                >
                                    <Icon
                                        img={CalendarRightArrow}
                                        width={"100%"}
                                        height={"100%"}
                                    ></Icon>
                                </ChangeMonthButton>
                            </div>
                        </div>
                    </>
                );
            }}
        ></DatePicker>
    );
}

export default SingleDatePicker;

type CustomInputProps = { value?: Date; onClick?: () => void };

const CustomInput = React.forwardRef((props: CustomInputProps, ref: any) => {
    const { value, onClick } = props;
    return (
        <DatePickerButton onClick={onClick} ref={ref}>
            <CalendarImg />
            <ContourLine />
            <LabelWrapper>
                <DatePickerText>{value}</DatePickerText>
            </LabelWrapper>
        </DatePickerButton>
    );
});

const DatePickerButton = styled.button`
    padding: 10px 12px;
    display: flex;
    align-items: center;
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid #d0d0d0;

    &:hover {
        cursor: pointer;
        border: 1px solid #ffcd00;
    }

    &:focus {
        border: 1px solid #ffcd00;
    }
`;

const CalendarImg = styled.div`
    background: url(${Calendar}) center center no-repeat;
    width: 14px;
    height: 14px;
`;

const ContourLine = styled.div`
    width: 1px;
    height: 12px;
    margin: 0 10px;
    background-color: #d0d0d0;
`;

const LabelWrapper = styled.div`
    margin-right: 10px;
    font-family: NanumSquareRound;
    > label {
        cursor: pointer;
    }
`;

const ChangeMonthButton = styled.button`
    border: none;
    background-color: #fff;
    cursor: pointer;
    width: 16px;
    height: 16px;
    padding: 0px;
    margin-right: 8px;
`;

const CurrentMonth = styled.span`
    font-size: 14px;
    font-weight: 700;
    font-family: NanumSquareRound;
    letter-spacing: -0.42px;
`;

const DatePickerText = styled.span`
    font-size: 14px;
    height: 16px;
    text-align: center;
    line-height: 16px;
    font-weight: 700;
`;
