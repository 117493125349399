import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { DragDropContext, DropResult, DroppableId } from "react-beautiful-dnd";
import RangeDatePicker from "components/molecules/rangeDatePicker";
import { ModalStation } from "./ModalStation";
import { media } from "styles/media";
import { WarningIcon } from "assets/images";
import StatusPopUp from "./Modal/StatusPopUp";
import { GlobalContext } from "context/global";
import OverBookingPopUp from "./Modal/OverBookingPopUp";
import moment from "moment";
import TicketRequest from "http/ticket";
import DuplicatePopUp from "./Modal/DuplicatePopUp";
import CancelPopUp from "./Modal/CancelPopUp";
const ticketRequest = new TicketRequest();

type RegisterTicketProps = {
    onClose?: any;
    schedules?: any;
    order?: number;
    studentId?: number | undefined;
    index?: number;
    details?: any;
    seatInfo?: any;
    isLast?: boolean;
    busType?: string;
    dispatchCode: string | undefined;
    day?: number;
    dispatchSearch?: any;
    dispatchType?: string;
    getDispatchesDetail?: any;
    busEndDate?: string;
    setDetails?: React.Dispatch<React.SetStateAction<any>>;
};

const whether = [
    {
        key: 1,
        value: "예",
    },
    {
        key: 2,
        value: "아니오",
    },
];

const initialCheckBoxes = [
    { id: "mon", value: "월", checked: false },
    { id: "tue", value: "화", checked: false },
    { id: "wed", value: "수", checked: false },
    { id: "thu", value: "목", checked: false },
    { id: "fri", value: "금", checked: false },
    { id: "sat", value: "토", checked: false },
    { id: "sun", value: "일", checked: false },
];

type StudentsProps = {
    id: number;
    pickUpStationId: number;
    takeOffStationId: number;
};

type OverBookingDataProps = {
    year?: string;
    seatOccupancyRates?: any[];
};

function RegisterTicket(props: RegisterTicketProps) {
    const {
        details,
        onClose,
        studentId,
        seatInfo,
        busType,
        dispatchCode,
        day,
        dispatchSearch,
        dispatchType,
        getDispatchesDetail,
        busEndDate,
        setDetails,
    } = props;
    const { setToast } = useContext(GlobalContext);
    const [chgSchedules, setChgSchedules] = useState<any[]>([]);
    const [startDate, setStartDate] = useState<Date>();
    const [endDate, setEndDate] = useState<Date>(new Date(busEndDate));
    const [updateDetails, setUpdateDetails] = useState(details);
    const [weekCnt, setWeekCnt] = useState<Number>(0);
    const [selectedValue, setSelectedValue] = useState("");
    const [addOtherDays, setAddOtherDays] = useState<boolean>(false);
    const [selectAll, setSelectAll] = useState(false);
    const [checkBoxes, setCheckBoxes] = useState(initialCheckBoxes);
    const [students, setStudents] = useState<StudentsProps[]>([]);
    const [edit, setEdit] = useState<undefined | any>([]);
    const [registerPopUp, setRegisterPopUp] = useState<boolean>(false);
    const [successRegister, setSuccessRegister] = useState<boolean>(false);
    const [showOverBooking, setShowOverBooking] = useState<boolean>(false);
    const [showDuplicate, setShowDuplicate] = useState<boolean>(false);
    const [cancelModal, setCancelModal] = useState<boolean>(false);
    const [overBookingData, setOverBookingData] = useState<
        OverBookingDataProps[]
    >([]);
    const [duplicateData, setDuplicateData] = useState<string[]>([]);
    const [overBookingWarning, setOverBookingWarning] = useState(false);
    const [duplicateWarning, setDuplicateWarning] = useState(false);
    const [overBookingStartDate, setOverBookingStartDate] = useState<string>();
    const [detailsIds, setDetailsIds] = useState<number[]>([]);

    const academyId = localStorage.getItem("academyId");

    const formatStartDate = moment(startDate).format("yyyy-MM-DD");
    const formatEndDate = moment(endDate).format("yyyy-MM-DD");

    useEffect(() => {
        if (endDate !== null) {
            changeDateDetails();
        }
    }, [startDate, endDate]);

    //startDate, endDate 파라미터 추가했을 때 다시 호출
    const changeDateDetails = async () => {
        const params = {
            startDate: formatStartDate,
            endDate: formatEndDate,
            dispatchCode,
        };
        await ticketRequest.getUpdateDispatchesDetail(params, (res: any) => {
            setUpdateDetails(res.data.data.courses);
        });
    };

    // overbooking 결과 받은 후 날짜 변경했을 때 warning 삭제
    useEffect(() => {
        if (startDate !== new Date(overBookingStartDate)) {
            setOverBookingWarning(false);
        }
    }, [startDate]);

    // 학생 등록 api
    const registerStudentsTicket = async () => {
        const overBookingParams = {
            boardingCount: edit?.length,
            dispatchCode: dispatchCode,
            startDate: formatStartDate,
            endDate: formatEndDate,
        };
        const params = {
            academyId: Number(academyId),
            startDate: formatStartDate,
            endDate: formatEndDate,
            dispatchCodes: [dispatchCode],
            students,
        };

        await ticketRequest.getOverBookingInfo(
            overBookingParams,
            (res: any) => {
                // 초과인원 없을 때
                if (res.data.data?.length === 0) {
                    ticketRequest.postExistStudentTicket(params, (res: any) => {
                        // 중복인원 없을 때
                        if (res.data.data?.length === 0) {
                            ticketRequest.postCreateStudentTicket(
                                params,
                                (res: any) => {
                                    // 초과, 중복인원 없으므로 등록 가능
                                    setRegisterPopUp(false);
                                    setSuccessRegister(true);
                                    dispatchSearch(
                                        day,
                                        dispatchType,
                                        academyId
                                    );
                                    getDispatchesDetail(dispatchCode);
                                }
                            );
                            // 중복인원 있을 때
                        } else if (res.data.data?.length > 0) {
                            setDuplicateData(res.data.data);
                            setRegisterPopUp(false);
                            setShowDuplicate(true);
                            setDuplicateWarning(true);
                            setOverBookingWarning(false);
                        }
                    });
                    // 초과인원 있을 때
                } else if (res.data.data?.length > 0) {
                    setRegisterPopUp(false);
                    setOverBookingWarning(true);
                    setOverBookingData(res.data.data);
                    setOverBookingStartDate(params?.startDate);
                }
            }
        );
    };

    const handleConfirmButton = () => {
        setSuccessRegister(false);
        onClose(false);
    };

    const handleOverBookingConfirmButton = () => {
        setShowOverBooking(false);
    };

    const handleDuplicateConfirmButton = () => {
        setShowDuplicate(false);
    };

    // 전체 선택
    const handleSelectedAll = () => {
        const updateCheckBoxes = checkBoxes.map((checkbox) => ({
            ...checkbox,
            checked: !selectAll,
        }));
        setSelectAll(!selectAll);
        setCheckBoxes(updateCheckBoxes);
    };

    // 개별 선택
    const handleCheckBoxChange = (checkboxId) => {
        const updatedCheckBoxes = checkBoxes.map((checkbox) =>
            checkbox.id === checkboxId
                ? { ...checkbox, checked: !checkbox.checked }
                : checkbox
        );
        setCheckBoxes(updatedCheckBoxes);

        const allChecked = updatedCheckBoxes.every(
            (checkbox) => checkbox.checked
        );
        setSelectAll(allChecked);
    };
    // checked true인 객체
    // const arr = checkBoxes.filter((item) => item.checked === true);

    const changeSelectedValue = (e: any) => {
        setSelectedValue(e.target.id);
    };

    useEffect(() => {
        if (selectedValue === "예") {
            setAddOtherDays(true);
        } else setAddOtherDays(false);
    }, [selectedValue]);

    useEffect(() => {
        const dataStudentParams = edit?.map((obj: any) => ({
            id: obj.id,
            pickUpStationId: obj.pickUpStationId,
            takeOffStationId: obj.takeOffStationId,
        }));

        setStudents(dataStudentParams);
    }, [edit]);

    function onDragEnd(result: DropResult): void {
        // source - 현재 정류장, destination - 이동 정류장, draggableId - 학생 id
        const { source, destination, draggableId } = result;

        if (!result.destination) return;
        if (source.droppableId === destination?.droppableId) {
            return;
        }

        const _schedules = [...details];
        const targetInfo = draggableId.split("-"); //inbound-outbound
        let studentId = parseInt(targetInfo[1]); //studentId 학생 id

        let droppableId: DroppableId | undefined = destination?.droppableId; //이동할 정류장 id

        let check = _schedules.some(function () {
            if (source.droppableId !== destination?.droppableId) {
                if (targetInfo[0] === "inbound" && edit?.length > 0) {
                    const updatedEdit = edit?.map((obj: any) => {
                        if (obj.id === studentId) {
                            return {
                                ...obj,
                                pickUpStationId: parseInt(droppableId),
                            };
                        }
                        return obj;
                    });
                    setEdit(updatedEdit);
                }
                if (targetInfo[0] === "outbound" && edit?.length > 0) {
                    const updatedEdit = edit?.map((obj: any) => {
                        if (obj.id === studentId) {
                            return {
                                ...obj,
                                takeOffStationId: parseInt(droppableId),
                            };
                        }
                        return obj;
                    });
                    setEdit(updatedEdit);
                }
            }
        });

        if (check) {
            setDetails(_schedules);
            setChgSchedules([]);
            setChgSchedules(_schedules);
        }
    }

    let studentArr = [];
    for (let i = 0; i < updateDetails.length; i++) {
        const currentObj = updateDetails[i];
        const isLastObj = i === updateDetails.length - 1;

        if (busType === "등원" && !isLastObj) {
            studentArr = studentArr.concat(currentObj.station.students);
        }

        if (busType === "하원") {
            let arr = studentArr.concat(currentObj.station.students);
            const duplicate = arr.filter(
                (item, idx, self) =>
                    !self
                        .slice(0, idx)
                        .some((otherItem) => otherItem.id === item.id)
            );
            studentArr = duplicate;
        }
    }

    useEffect(() => {
        if (busType === "등원") {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            const inBoundStudent =
                updateDetails[updateDetails?.length - 1].station.students;
            const inBoundStudentIds = inBoundStudent?.map((item) => item.id);
            setDetailsIds(inBoundStudentIds);
        } else if (busType === "하원") {
            const outBoundStudent = updateDetails[0].station.students;
            const outBoundStudentIds = outBoundStudent?.map((item) => item.id);
            setDetailsIds(outBoundStudentIds);
        }
    }, [updateDetails]);

    return (
        <>
            <Container>
                <Header>
                    <Title>승차권 등록&nbsp;({busType})</Title>
                </Header>

                <Grid>
                    <LeftContainerWrapper weekCnt={weekCnt}>
                        <Card>
                            <SubTitle>승차기간을 선택해 주세요.</SubTitle>
                            <Span>
                                *<span style={{ fontWeight: 800 }}>오늘</span>
                                &nbsp;승차권 등록은&nbsp;
                                <span
                                    style={{
                                        color: "#FF7900",
                                        fontWeight: 800,
                                    }}
                                >
                                    불가능
                                </span>
                                합니다.
                            </Span>
                            <RangeDatePickerWrapper>
                                <RangeDatePicker
                                    startDate={startDate}
                                    endDate={endDate}
                                    setStartDate={setStartDate}
                                    setEndDate={setEndDate}
                                    selectedDay={day}
                                    maxDate={busEndDate}
                                    setWeekCnt={setWeekCnt}
                                />
                            </RangeDatePickerWrapper>
                        </Card>
                    </LeftContainerWrapper>

                    <Card>
                        <SubTitle>
                            각 정류장에 승차할 학생을 등록해 주세요.
                        </SubTitle>
                        <div>
                            <Span>
                                <span style={{ fontWeight: 800 }}>
                                    *선택하신 승차 기간에 등록되어 있는 학생
                                </span>
                                들이{" "}
                                <span style={{ fontWeight: 800 }}>
                                    모두 노출
                                </span>
                                됩니다.
                            </Span>
                        </div>
                        <div>
                            <Span>
                                버스 인승 :&nbsp;
                                <span
                                    style={{
                                        color: overBookingWarning
                                            ? "#E74D41"
                                            : "#585858",
                                        fontWeight: 800,
                                    }}
                                >
                                    {`${seatInfo.seat.validCount}인승`}
                                </span>
                            </Span>
                        </div>

                        {/* warning */}
                        {overBookingWarning && (
                            <WarningContainer>
                                <div
                                    style={{
                                        alignItems: "center",
                                        display: "flex",
                                    }}
                                >
                                    <WarningImg
                                        src={WarningIcon}
                                        alt="warning"
                                    />
                                    <WarningSpan>
                                        <span className="bold">승차 인원</span>
                                        이&nbsp;
                                        <span className="bold">초과</span>
                                        되었습니다. 기간 안에 등록 가능한
                                        인원은&nbsp;
                                        <span className="weight">
                                            {seatInfo.seat.validCount}명
                                        </span>
                                        입니다.
                                    </WarningSpan>
                                </div>
                                <OverBookingButton
                                    onClick={() => setShowOverBooking(true)}
                                >
                                    <OverBookingSpan>
                                        초과 인원 확인하기
                                    </OverBookingSpan>
                                </OverBookingButton>
                            </WarningContainer>
                        )}

                        {duplicateWarning && (
                            <WarningContainer>
                                <div
                                    style={{
                                        alignItems: "center",
                                        display: "flex",
                                    }}
                                >
                                    <WarningImg
                                        src={WarningIcon}
                                        alt="warning"
                                    />
                                    <WarningSpan>
                                        <span>
                                            이미{" "}
                                            <span style={{ fontWeight: 800 }}>
                                                동시간대(요일, 시간 포함) 다른
                                                호차
                                            </span>
                                            에 승차권이 등록되어 있어{" "}
                                            <span
                                                style={{
                                                    color: "#E74D41",
                                                    fontWeight: 800,
                                                }}
                                            >
                                                승차권 등록
                                            </span>
                                            이{" "}
                                            <span
                                                style={{
                                                    color: "#E74D41",
                                                    fontWeight: 800,
                                                }}
                                            >
                                                불가
                                            </span>
                                            합니다.
                                        </span>
                                    </WarningSpan>
                                </div>
                            </WarningContainer>
                        )}

                        <Course>
                            <DragDropContext onDragEnd={onDragEnd}>
                                {updateDetails.map(
                                    (detail: any, index: number) => {
                                        return (
                                            <ModalStation
                                                key={`detail-${index}`}
                                                order={index}
                                                detail={detail}
                                                schedules={details}
                                                studentId={studentId}
                                                index={index}
                                                isLast={
                                                    details.length - 1 === index
                                                }
                                                isFirst={0 === index}
                                                seatInfo={seatInfo}
                                                busType={busType}
                                                enrolledStudentList={studentArr}
                                                setEdit={setEdit}
                                                edit={edit}
                                                overBookingWarning={
                                                    overBookingWarning
                                                }
                                                overBookingData={
                                                    overBookingData
                                                }
                                                setOverBookingWarning={
                                                    setOverBookingWarning
                                                }
                                                setDuplicateWarning={
                                                    setDuplicateWarning
                                                }
                                                duplicateData={duplicateData}
                                                detailsIds={detailsIds}
                                            />
                                        );
                                    }
                                )}
                            </DragDropContext>
                        </Course>
                    </Card>
                </Grid>
                <ButtonWrapper>
                    <CancelButton
                        onClick={() => {
                            setCancelModal(true);
                        }}
                    >
                        <ButtonSpan>취소</ButtonSpan>
                    </CancelButton>
                    <ApplyButton
                        edit={edit}
                        startDate={startDate}
                        endDate={endDate}
                        onClick={
                            edit?.length > 0 &&
                            startDate !== null &&
                            endDate !== null
                                ? () => setRegisterPopUp(true)
                                : () => {}
                        }
                    >
                        <ButtonSpan>등록</ButtonSpan>
                    </ApplyButton>
                </ButtonWrapper>
                {registerPopUp && (
                    <StatusPopUp
                        title={"승차권 등록"}
                        contents={"승차권을 등록하시겠습니까?"}
                        width={"360px"}
                        rightButton={"등록"}
                        height={"200px"}
                        right={"30%"}
                        handler={setRegisterPopUp}
                        confirm={registerStudentsTicket}
                    />
                )}
                {successRegister && (
                    <StatusPopUp
                        title={"승차권 등록"}
                        contents={"승차권이 등록되었습니다."}
                        rightButton={"확인"}
                        type={"single"}
                        width={"360px"}
                        height={"200px"}
                        right={"30%"}
                        handler={setSuccessRegister}
                        confirm={handleConfirmButton}
                    />
                )}
                {showOverBooking && (
                    <OverBookingPopUp
                        width={"438px"}
                        height={"auto"}
                        right={"30%"}
                        data={overBookingData}
                        handler={setShowOverBooking}
                        confirm={handleOverBookingConfirmButton}
                    />
                )}
                {showDuplicate && (
                    <DuplicatePopUp
                        width={"360px"}
                        height={"auto"}
                        right={"30%"}
                        data={duplicateData}
                        handler={setShowDuplicate}
                        confirm={handleDuplicateConfirmButton}
                    />
                )}
                {cancelModal && (
                    <CancelPopUp
                        title={"승차권 등록 취소"}
                        rightButton={"아니오"}
                        leftButton={"예"}
                        width={"360px"}
                        height={"200px"}
                        right={"30%"}
                        handler={setCancelModal}
                        confirm={() => {
                            onClose(false);
                            setToast({
                                toggle: true,
                                toastStatus: "success",
                                message: `승차권 등록이 취소되었습니다.`,
                            });
                        }}
                    />
                )}
            </Container>
        </>
    );
}

export default RegisterTicket;

const Container = styled.div`
    width: 1175px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    padding-bottom: 68px;
    position: relative;
`;

const Header = styled.div`
    background-color: #fff;
    padding: 20px;
    border-radius: 16px 0px 0px 0px;
`;

const Title = styled.div`
    font-weight: 800;
    font-size: 18px;
    color: #174490;
    letter-spacing: -0.54px;
    line-height: 20px;
`;

const SubTitle = styled.div`
    color: #000;
    font-size: 14px;
    font-weight: 800;
    line-height: 22px;
    letter-spacing: -0.42px;
    margin-bottom: 4px;
`;

const Grid = styled.div`
    display: grid;
    grid-template-columns: 300px 815px;
    column-gap: 20px;
    height: 100vh;
    padding: 20px;
    overflow: scroll;

    ::-webkit-scrollbar {
        display: none; /* 크롬, 사파리, 오페라, 엣지 */
    }
`;

type LeftContainerProps = {
    weekCnt?: Number;
};

const LeftContainerWrapper = styled.div<LeftContainerProps>`
    display: flex;
    display: inline-block;
    flex-direction: column;
    min-width: 300px;
    max-height: ${({ weekCnt }) => (weekCnt === 6 ? "505px" : "470px")};
    /* gap: 20px; */
`;

const Card = styled.div`
    width: 100%;
    height: 100%;
    padding: 20px;
    background-color: #fff;
    border-radius: 6px;
    border: 1px solid #e7e7e7;
    overflow: scroll;
    ::-webkit-scrollbar {
        display: none; /* 크롬, 사파리, 오페라, 엣지 */
    }
`;

const Span = styled.span`
    font-weight: 400;
    color: #585858;
    line-height: 20px;
    font-size: 11px;
    font-family: NanumSquareRound;
`;

export const ButtonSpan = styled.span`
    color: #000;
    font-size: 14px;
    line-height: 16px;
    font-weight: 800;
    font-family: NanumSquareRound;
`;

const RangeDatePickerWrapper = styled.div``;

export const ButtonWrapper = styled.div`
    display: flex;
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 100%;
    height: 68px;
    background-color: #fff;
    border-radius: 0px 0px 0px 16px;
    padding: 16px 24px;
`;

export const CancelButton = styled.button`
    margin-left: auto;
    margin-right: 4px;
    width: 70px;
    height: 36px;
    border-radius: 30px;
    background: #efefef;
    border: 1px solid #efefef;
`;

type ApplyButtonProps = {
    edit?: any;
    startDate?: any;
    endDate?: any;
};

export const ApplyButton = styled.button<ApplyButtonProps>`
    width: 70px;
    height: 36px;
    border-radius: 30px;
    background-color: ${(props) =>
        props?.edit?.length > 0 &&
        props?.startDate !== null &&
        props?.endDate !== null
            ? "#FFCD00"
            : "#fff1b3"};
    border: 1px solid #fff1b3;

    :hover {
        cursor: ${(props) =>
            props.edit?.length > 0 &&
            props?.startDate !== null &&
            props?.endDate !== null
                ? "pointer"
                : "default"};
    }
    > span {
        color: ${(props) =>
            props.edit?.length > 0 &&
            props?.startDate !== null &&
            props?.endDate !== null
                ? "#000"
                : "#cccccc"};
    }
`;

const WarningContainer = styled.div`
    width: 100%;
    height: 40px;
    border-radius: 8px;
    background: rgba(231, 77, 65, 0.1);
    display: flex;
    padding: 8px;
    align-items: center;
    margin-top: 10px;
    justify-content: space-between;
`;

const WarningImg = styled.img`
    width: 16px;
    height: 16px;
    margin-right: 4px;
`;

const WarningSpan = styled.span`
    color: #585858;
    font-size: 11px;
    font-weight: 400;
    line-height: 16px;

    .bold {
        color: #e74d41;
        font-weight: 800;
    }

    .weight {
        font-weight: 700;
    }
`;

const OverBookingButton = styled.button`
    width: 102px;
    height: 24px;
    padding: 2px 8px;
    border-radius: 4px;
    background: #e74d41;
    border: none;
`;

const OverBookingSpan = styled.span`
    color: #fff;
    text-align: center;
    font-size: 11px;
    font-weight: 700;
    line-height: 20px;
    font-family: NanumSquareRound;
`;

const Course = styled.ul`
    width: 100%;
    /* max-height: calc(100vh - 280px); */
    height: 100%;
    overflow: auto;
    padding: 32px 0px;
    border-radius: 0 0 30px 30px;
    background-color: ${({ theme }) => theme.colors.white};
    ${media.tablet_} {
        max-height: 100%;
    }
    position: relative;

    ::-webkit-scrollbar {
        display: none; /* 크롬, 사파리, 오페라, 엣지 */
    }
`;
