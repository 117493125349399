import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { media } from "styles/media";
import moment from "moment";
import TicketRequest from "http/ticket";
import SingleDatePicker from "components/molecules/datepicker";
import { UncheckedCheckbox, CheckedCheckbox } from "assets/images";
import { DeletePopUp } from "./Modal/DeletePopUp";
import StatusPopUp from "./Modal/StatusPopUp";
const ticketRequest = new TicketRequest();

type DeleteTicketProps = {
    onClose?: any;
    schedules?: any;
    busType?: string;
    dispatchCode: string | undefined;
    day?: number;
    dispatchSearch?: any;
    dispatchType?: string;
    getDispatchesDetail?: any;
};

function DeleteTicket(props: DeleteTicketProps) {
    const {
        onClose,
        dispatchCode,
        schedules,
        busType,
        day,
        dispatchSearch,
        dispatchType,
        getDispatchesDetail,
    } = props;
    const academyId = localStorage.getItem("academyId");
    const [students, setStudents] = useState([]);
    const [allSelect, setAllSelect] = useState<boolean>(false);
    const [selectedStudent, setSelectedStudent] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [deletePopUp, setDeletePopUp] = useState<boolean>(false);
    const [completeDelete, setCompleteDelete] = useState<boolean>(false);
    const today = new Date();
    let tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);

    const dateChange = (date) => {
        setStartDate(date);
    };

    useEffect(() => {
        if (busType === "등원") {
            setStudents(schedules[schedules?.length - 1].station.students);
        } else if (busType === "하원") {
            setStudents(schedules[0].station.students);
        }
    }, [schedules]);

    const studentIds = selectedStudent?.map((obj) => obj.id);

    // 학생 승차권 삭제 api
    const deleteStudentsTicket = async () => {
        const data = {
            deleteStartDate:
                startDate === ""
                    ? moment(tomorrow).format("yyyy-MM-DD")
                    : moment(startDate).format("yyyy-MM-DD"),
            dispatchCode,
            studentIds,
        };
        await ticketRequest.deleteStudentTicket(data, (res: any) => {
            if (res.data.code === "OK") {
                setDeletePopUp(false);
                setCompleteDelete(true);
            } else console.error(`${res.data.message}`);
        });
    };

    // 전체 선택 체크 여부
    const handleAllSelect = () => {
        setAllSelect(!allSelect);
        if (!allSelect) {
            setSelectedStudent(students);
        } else {
            setSelectedStudent([]);
        }
    };

    //리스트에서 학생 선택
    const handleClick = (items: any) => {
        const newStudent = items;
        const selectedStudentList = [...selectedStudent, newStudent];
        setSelectedStudent(selectedStudentList);
    };

    // 선택한 리스트에서 학생 제거
    const deleteStudent = (deleteValue: any) => {
        const deleteStudentList = selectedStudent.filter(
            (item) => item !== deleteValue
        );
        setSelectedStudent(deleteStudentList);
    };

    return (
        <>
            <Container>
                <Header>
                    <Title>승차권 삭제</Title>
                </Header>

                <Grid>
                    <LeftContainerWrapper>
                        <Card>
                            <SubTitle>승차 종료일을 선택해 주세요.</SubTitle>
                            <Span>
                                *선택한 날짜를 포함하여 등록된 승차권이 모두
                                삭제됩니다.
                            </Span>
                            <Span>
                                단, &nbsp;
                                <span style={{ fontWeight: 800 }}>
                                    당일 승차권 삭제
                                </span>
                                는&nbsp;
                                <span
                                    style={{
                                        color: "#FF7900",
                                        fontWeight: 800,
                                    }}
                                >
                                    불가능
                                </span>
                                합니다.
                            </Span>
                            <RangeDatePickerWrapper>
                                <SingleDatePicker
                                    dateChange={dateChange}
                                    startDate={
                                        startDate === "" ? tomorrow : startDate
                                    }
                                />
                            </RangeDatePickerWrapper>
                        </Card>
                    </LeftContainerWrapper>

                    <Card>
                        <SubTitle>
                            승차권을 삭제할 학생을 선택해 주세요.
                        </SubTitle>
                        <TableBox>
                            <SelectContainer>
                                <Span>
                                    총{" "}
                                    <span style={{ fontWeight: 800 }}>
                                        {selectedStudent?.length}명
                                    </span>{" "}
                                    선택
                                </Span>
                                <ResetSpan
                                    onClick={() => setSelectedStudent([])}
                                >
                                    선택 초기화
                                </ResetSpan>
                            </SelectContainer>
                            <table>
                                <thead>
                                    <tr>
                                        <th onClick={() => handleAllSelect()}>
                                            <img
                                                src={
                                                    allSelect
                                                        ? CheckedCheckbox
                                                        : UncheckedCheckbox
                                                }
                                                alt=""
                                                style={{
                                                    width: "18px",
                                                    height: "18px",
                                                    cursor: "pointer",
                                                    transform:
                                                        "translateY(2px)",
                                                }}
                                            />{" "}
                                        </th>
                                        <th>
                                            <div
                                                style={{
                                                    lineHeight: "16px",
                                                    transform:
                                                        "translateY(-2px)",
                                                }}
                                            >
                                                학년
                                            </div>
                                        </th>
                                        <th>
                                            <div
                                                style={{
                                                    lineHeight: "16px",
                                                    transform:
                                                        "translateY(-2px)",
                                                }}
                                            >
                                                학생이름
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {students?.map((item) => (
                                        <tr
                                            key={item?.id}
                                            className={
                                                selectedStudent.includes(item)
                                                    ? "selected"
                                                    : ""
                                            }
                                            onClick={
                                                selectedStudent.includes(item)
                                                    ? () => deleteStudent(item)
                                                    : () => handleClick(item)
                                            }
                                        >
                                            {selectedStudent.includes(item) ? (
                                                <td>
                                                    <img
                                                        src={CheckedCheckbox}
                                                        alt=""
                                                        style={{
                                                            width: "18px",
                                                            height: "18px",
                                                            transform:
                                                                "translateY(2px)",
                                                        }}
                                                    />
                                                </td>
                                            ) : (
                                                <td>
                                                    <img
                                                        src={UncheckedCheckbox}
                                                        alt=""
                                                        style={{
                                                            width: "18px",
                                                            height: "18px",
                                                            transform:
                                                                "translateY(2px)",
                                                        }}
                                                    />
                                                </td>
                                            )}
                                            <td>
                                                <div
                                                    style={{
                                                        lineHeight: "16px",
                                                        transform:
                                                            "translateY(-2px)",
                                                    }}
                                                >
                                                    {item?.nickName === ""
                                                        ? "-"
                                                        : item.nickName}
                                                </div>
                                            </td>
                                            <td>
                                                <div
                                                    style={{
                                                        lineHeight: "16px",
                                                        transform:
                                                            "translateY(-2px)",
                                                    }}
                                                >{`${
                                                    item.name
                                                }(${item.parentPhone.slice(
                                                    -4
                                                )})`}</div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </TableBox>
                    </Card>
                </Grid>
                <ButtonWrapper>
                    <CancelButton
                        onClick={() => {
                            onClose(false);
                        }}
                    >
                        <ButtonSpan>취소</ButtonSpan>
                    </CancelButton>
                    <ApplyButton
                        selectedStudent={selectedStudent}
                        onClick={() => setDeletePopUp(true)}
                    >
                        <ButtonSpan>삭제</ButtonSpan>
                    </ApplyButton>
                </ButtonWrapper>
                {deletePopUp && (
                    <DeletePopUp
                        title={"승차권 삭제"}
                        width={"360px"}
                        height={"218px"}
                        right={"30%"}
                        handler={setDeletePopUp}
                        date={
                            startDate === ""
                                ? moment(tomorrow).format("yyyy-MM-DD")
                                : moment(startDate).format("yyyy-MM-DD")
                        }
                        confirm={() => deleteStudentsTicket()}
                    />
                )}
                {completeDelete && (
                    <StatusPopUp
                        title={"승차권 삭제 완료"}
                        contents={"승차권이 삭제되었습니다."}
                        type={"single"}
                        rightButton={"확인"}
                        width={"360px"}
                        height={"200px"}
                        right={"30%"}
                        confirm={() => {
                            onClose(false);
                            setCompleteDelete(false);
                            dispatchSearch(day, dispatchType, academyId);
                            getDispatchesDetail(dispatchCode);
                        }}
                    />
                )}
            </Container>
        </>
    );
}

export default DeleteTicket;

const Container = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    padding-bottom: 68px;
    position: relative;
`;

const Header = styled.div`
    background-color: #fff;
    padding: 20px;
    border-radius: 16px 0px 0px 0px;
`;

const Title = styled.div`
    font-weight: 800;
    font-size: 18px;
    color: #174490;
    letter-spacing: -0.54px;
    line-height: 20px;
`;

const SubTitle = styled.div`
    color: #000;
    font-size: 14px;
    font-weight: 800;
    line-height: 22px;
    letter-spacing: -0.42px;
    margin-bottom: 4px;
`;

const Grid = styled.div`
    display: grid;
    grid-template-columns: 370px calc(100% - 394px);
    column-gap: 20px;
    height: 100vh;
    padding: 20px;
    overflow: scroll;

    ::-webkit-scrollbar {
        display: none; /* 크롬, 사파리, 오페라, 엣지 */
    }
`;

const LeftContainerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 370px;
    max-height: 154px;
    gap: 20px;
`;

const Card = styled.div`
    width: 100%;
    height: 100%;
    padding: 20px;
    background-color: #fff;
    border-radius: 6px;
    border: 1px solid #e7e7e7;
    overflow: scroll;
    display: flex;
    flex-direction: column;

    ::-webkit-scrollbar {
        display: none; /* 크롬, 사파리, 오페라, 엣지 */
    }
`;

const Span = styled.span`
    font-weight: 400;
    color: #585858;
    line-height: 20px;
    font-size: 11px;
    font-family: NanumSquareRound;
`;

const ResetSpan = styled.span`
    font-weight: 400;
    color: #585858;
    font-size: 12px;
    text-decoration: underline;
    font-family: NanumSquareRound;
    cursor: pointer;
`;

export const ButtonSpan = styled.span`
    color: #000;
    font-size: 14px;
    line-height: 16px;
    font-weight: 800;
    font-family: NanumSquareRound;
`;

const RangeDatePickerWrapper = styled.div`
    width: 260px;
    margin-top: 16px;
`;

export const ButtonWrapper = styled.div`
    display: flex;
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 100%;
    height: 68px;
    background-color: #fff;
    border-radius: 0px 0px 0px 16px;
    padding: 16px 24px;
`;

export const CancelButton = styled.button`
    margin-left: auto;
    margin-right: 4px;
    width: 70px;
    height: 36px;
    border-radius: 30px;
    background: #efefef;
    border: 1px solid #efefef;
`;

type ApplyButtonProps = {
    selectedStudent?: any;
};

export const ApplyButton = styled.button<ApplyButtonProps>`
    width: 70px;
    height: 36px;
    border-radius: 30px;
    background-color: ${(props) =>
        props.selectedStudent?.length > 0 ? "#FFCD00" : "#fff1b3"};
    border: 1px solid #fff1b3;

    > span {
        color: ${(props) =>
            props.selectedStudent?.length > 0 ? "#000" : "#cccccc"};
    }
`;

const SelectContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 0px;
    border-bottom: 1px solid #e1e1e1;
`;

const TableBox = styled.div`
    width: 100%;
    height: 100%;
    overflow: scroll;
    border-radius: 8px;
    border: 1px solid #dfdfdf;
    background: #fff;
    padding: 8px 16px;
    margin-top: 12px;

    ::-webkit-scrollbar {
        display: none; /* 크롬, 사파리, 오페라, 엣지 */
    }

    table {
        width: 100%;
        border-spacing: 0;
        min-width: 550px;
        ${media.tablet} {
            min-width: auto;
        }

        .selected {
            background-color: #fffae5;
        }
        thead {
            tr {
                color: ${({ theme }) => theme.colors.blue};
                font-weight: 800;

                th {
                    position: sticky;
                    background-color: white;
                    top: 0px;
                    box-shadow: 0px 1px 0px 0 rgba(0, 0, 0, 0.1);
                    line-height: 16px;
                    color: #174490;
                }
            }
        }
        tr {
            border-bottom: 1px solid #e1e1e1;

            :last-child {
                td {
                    border-bottom: none;
                }
            }
        }
        tbody {
            tr {
                line-height: 14px;
                max-height: 42px;
                :hover {
                    cursor: pointer;
                    border-radius: 10px;
                    -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
                    -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
                    background-color: #fffae5;
                }
            }
        }

        th,
        td {
            text-align: center;
            margin: 0;
            padding: 20px 10px;
            border-bottom: 1px solid #e1e1e1;
            justify-content: center;
            line-height: 14px;
            max-height: 42px;
            :last-child {
                border-right: 0;
            }
            ${media.desktop} {
                padding: 12px 16px;
            }
        }
    }
`;
