import React, { useCallback, useContext, useEffect, useState, useRef } from "react";
import { styled } from "styles/theme";
import DispatchRequest from "../../http/dispatch";
import { formattingHHmm } from "../DateUtil";
import { ScheduleServiceContext } from "../../context/scheduleService";
import WarningPopUp from "./WarningPopUp";
import { PopUp2 } from "../layout/PopUp2";
import { css } from "styles/theme-components";
import { media } from "styles/media";
import ArrowDown from "assets/images/icons/arrow_down_icon_grey.svg";
import Moment from "moment";
import SelectCheck from "assets/images/icons/check_icon_yellow.svg";

const dispatchRequest = new DispatchRequest();

interface SelectTimeProps {
    academyId: number;
    selectDate: string;
    params: any;
    row: number;
    seatFull: boolean;
}

const FlexWrapper = styled.div`
    display: flex;
    maxwidth: 140px;
    css ::after {
        transform: translate(-50%, -50%);
    }
`;

const Type = styled.div`
    display: block;
    line-height: 22px;
    padding: 2px 12px;
`;

const BoardSwitchWrap = styled.div`
    display: inline-block;
    position: relative;
`;

interface SwitchProps {
    editable: boolean;
    propsStatus: string;
    selectDate: string;
    params: any;
    row: number;
    seatFull: boolean;
    focusVisible: boolean;
}

const Switch = styled.div<SwitchProps>`
    // position: relative;
    display: inline-block;
    width: 80px;
    height: 26px;
    padding: 6px 25px 6px 12px;
    font-size: 12px;
    margin-left: 2px;
    border: 1px solid #cacaca;
    border-radius: 60px;
    line-height: 1;
    background: url(${ArrowDown}) center right 12px no-repeat;
    background-size: 14px;
    margin-right: 6px;
    ${({ focusVisible }) =>
        focusVisible &&
        css`
            :focus {
                outline: none;
                border: 1px solid ${({ theme }) => theme.colors.yellow};
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
            }
            :-webkit-autofill,
            :-webkit-autofill:focus {
                border: 1px solid ${({ theme }) => theme.colors.yellow};
                -webkit-box-shadow: 0 0 0px 1000px #fff inset;
                transition: background-color 5000s ease-in-out 0s;
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
            }
        `}

    :hover {
        cursor: not-allowed;
    }
    ${({ editable }) =>
        editable === true
            ? css`
                  border: 1px solid #cacaca;
                  background-color: ${({ theme }) => theme.colors.white};
                  :hover {
                      cursor: pointer;
                  }
              `
            : css`
                  border: 1px solid #eee;
                  background-color: #eee;
                  color: #666;
                  pointer-events: none;
                  :hover {
                      cursor: not-allowed;
                  }
              `};
    ${({ seatFull }) => (seatFull === true ? `border-color: #FF7900;` : `border-color: #CACACA;`)};
    option {
        display: none;
    }
    ${media.tablet} {
        option {
            display: none;
        }
    }
`;

interface OptionListProps {
    optionHeight: number;
    optionListOn: boolean;
}
const OptionList = styled.div<OptionListProps>`
    opacity: ${({ optionListOn }) => (optionListOn === true ? 1 : 0)};
    position: absolute;
    width: 80px;
    height: ${({ optionHeight }) => optionHeight};
    top: 0.1px;
    left: 2px;
    background: #ffffff;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.18);
    border-radius: 13px;
    display: flex;
    align-items: center;
    z-index: ${({ optionListOn }) => (optionListOn === true ? 10 : -1)};
    li {
        padding: 9px 20px;
        width: 80px;
        :hover {
            background-color: #f7f7f7;
            cursor: pointer;
        }
    }
`;

interface TimeListProps {
    selectedTime: string;
    dispatchTime: string;
    index: number;
}

const TimeList = styled.li<TimeListProps>`
    background: url(${SelectCheck}) center left 4px no-repeat;

    :nth-child(1) {
        border-top-left-radius: 13px;
        border-top-right-radius: 13px;
    }

    ${({ selectedTime, dispatchTime, index }) =>
        selectedTime === dispatchTime
            ? css`
                  :nth-child(${index}) {
                      background: url(${SelectCheck}) center left 4px no-repeat;
                  }
              `
            : css`
                  // 모든 곳에서 나옴
                  background-image: none;
              `};
`;

export const SelectedTime: React.FC<SelectTimeProps> = ({ academyId, selectDate, params, row, seatFull }) => {
    const { scheduleList, setScheduleList } = useContext(ScheduleServiceContext);
    const divRef = useRef<any>();
    const [timeData, setTimeData] = useState([]);
    const currentTime = formattingHHmm(params.scheduleTime);
    const [selectedTime, setSelectedTime] = useState(currentTime);
    const [paramSeatFull, setParamSeatFull] = useState<boolean>(false);
    const [seatFullPopUp, setSeatFullPopUp] = useState<boolean>(false);
    const [optionActive, setOptionActive] = useState(false);
    const [time1, setTime1] = useState<string>(currentTime);
    const [focus, setFocus] = useState(false);

    const timeOnClick = (e: any) => {
        setTime1(e);
    };

    const activeCloseOption = (e: any) => {
        const optionList = e.target.className.indexOf("switchClass");

        if (optionList === -1) {
            setOptionActive(false);
            document.removeEventListener("click", activeCloseOption, false);
        } else {
            if (divRef.current !== e.target) {
                setOptionActive(false);
                document.removeEventListener("click", activeCloseOption, false);
            }
        }
        setFocus(true);
    };

    const moveZeros = function (arr: any[], compareStr: any) {
        // 버스이름 같은 것 끼리 묶고, comparestr과 같은 것부터 배열에 입력
        let filterdata = arr.filter(x => x.busName === compareStr).concat(arr.filter(x => x.busName !== compareStr));

        const arrTimeSort = (par1: any, par2: any) => {
            return par1.dispatchTime.replace(":", "") - par2.dispatchTime.replace(":", "");
        };

        return filterdata.sort(arrTimeSort);
    };

    const getApiParams = useCallback(async () => {
        let result: any[] = [];
        console.log(params);

        dispatchRequest.search(
            academyId,
            selectDate,
            params,
            (response: any) => {
                if (response.status === -1 || (response.status === 200 && response.data.code !== "OK")) {
                    alert(`[${response.data.code}] ${response.data.message}`);
                } else {
                    const sorted = moveZeros(response.data.data, params.busName);
                    const x = sorted.reduce((accumulator: any[], current: any) => {
                        if (current.busName === params.busName) {
                            if (!accumulator.some(x => !current.seatFull && x.dispatchTime === current.dispatchTime)) {
                                accumulator.push(current);
                            }
                        } else {
                            if (!accumulator.some(x => !current.seatFull && x.dispatchTime === current.dispatchTime)) {
                                accumulator.push(current);
                            }
                        }
                        return accumulator;
                    }, []);
                    console.log(x);
                    // @ts-ignore
                    setTimeData(x);
                }
            },
            () => {
                setTimeData([]);
            }
        );

        return { scheduleStationsList: result };
    }, [academyId, selectDate, params]);

    useEffect(() => {
        getApiParams().then();
    }, [getApiParams]);

    useEffect(() => {
        setSeatFullPopUp(paramSeatFull);
    }, [paramSeatFull]);

    const changeSchedule = async (selectIndex: number) => {
        let selectedData: any = timeData[selectIndex];
        let result: any = [];
        scheduleList.forEach((schedule: any, index: number) => {
            if (index === row) {
                let boardModifyed = schedule.changeBoardSwitch === "" || schedule.changeBoardSwitch === undefined;

                // 보드가 수정이 되고, 타임이 원래로 돌렸을 때 : 타임 초기화
                if (
                    schedule.boardSwitch !== schedule.changeBoardSwitch &&
                    boardModifyed &&
                    formattingHHmm(schedule.scheduleTime) === formattingHHmm(selectedData.dispatchTime)
                ) {
                    schedule.changeBusName = "";
                    schedule.changeCode = "";
                    schedule.changeTime = "";
                    schedule.seatFull = selectedData.seatFull;
                } else if (
                    formattingHHmm(selectedData.dispatchTime) === formattingHHmm(schedule.scheduleTime) &&
                    boardModifyed
                ) {
                    // 보드와 타임이 원래대로 돌렸을 때 : 초기화
                    schedule.changeBusName = "";
                    schedule.changeCode = "";
                    schedule.changeTime = "";
                    schedule.seatFull = selectedData.seatFull;
                    schedule.changeBoardSwitch = "";
                } else {
                    // 타임이 변경 되었을 때
                    schedule.changeBusName = selectedData.busName;
                    schedule.changeCode = selectedData.code;
                    schedule.changeTime = selectedData.dispatchTime;
                    schedule.seatFull = selectedData.seatFull;
                }

                setParamSeatFull(selectedData.seatFull);
                setSelectedTime(selectedData.dispatchTime);
            }
            result.push(schedule);
        });

        console.log(result);
        setScheduleList(result);
    };

    function checkEditable() {
        if (params.changeBoardSwitch !== undefined && params.changeBoardSwitch !== "") {
            return params.changeBoardSwitch === "WILL_BOARD";
        } else if (params.boardSwitch !== "WILL_BOARD") {
            return false;
        }

        let today = Moment().format("YYYY-MM-DD"); // 포맷팅한 현재 날짜
        let compareTime = Moment(params.startTime, "HH:mm");
        let convertSelectDate = Moment(selectDate); // 모먼트로 컨버트한 날짜
        let nowTime = Moment(Moment().format("HH:mm"), "HH:mm");
        let formatStartDate = convertSelectDate.format("YYYY-MM-DD"); // 포맷한 컨버트 날짜

        if (Moment(today).isAfter(convertSelectDate)) {
            return false;
        } else {
            if (today === formatStartDate && nowTime.isAfter(compareTime.add(-30, "minutes"))) {
                return false; // 비활성화
            }
        }

        return true;
    }

    return (
        <div style={{ width: "170px", display: "inline-block" }}>
            <FlexWrapper>
                <Type>{params.scheduleType}</Type>
                <div style={{ position: "relative", display: "inline-block" }}>
                    <BoardSwitchWrap>
                        <Switch
                            className="switchClass"
                            onClick={e => {
                                e.preventDefault();
                                setOptionActive(true);
                                document.addEventListener("click", activeCloseOption);
                            }}
                            editable={checkEditable()}
                            propsStatus={""}
                            selectDate={""}
                            params={""}
                            row={0}
                            seatFull={paramSeatFull}
                            tabIndex={-1}
                            focusVisible={focus}
                            ref={divRef}
                        >
                            <OptionList optionHeight={timeData.length * 30} optionListOn={optionActive}>
                                <ul className="option-list" style={{ borderRadius: "13px", left: "1px" }}>
                                    {timeData.map((time: any, index) => {
                                        return (
                                            <TimeList
                                                dispatchTime={time.dispatchTime}
                                                selectedTime={selectedTime}
                                                index={index}
                                                key={index}
                                                value={time.dispatchTime}
                                                onClick={() => {
                                                    timeOnClick(time.dispatchTime);
                                                    changeSchedule(index);
                                                }}
                                            >
                                                {time.dispatchTime}
                                            </TimeList>
                                        );
                                    })}
                                </ul>
                            </OptionList>
                            {paramSeatFull ? <div style={{ color: "#FF7900" }}>{time1}</div> : <div>{time1}</div>}
                        </Switch>
                    </BoardSwitchWrap>
                </div>
            </FlexWrapper>
            {seatFullPopUp && (
                <PopUp2
                    open={true}
                    handler={setSeatFullPopUp}
                    contents={<WarningPopUp setPopUpSeatFul={setSeatFullPopUp} />}
                />
            )}
        </div>
    );
};
