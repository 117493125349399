import React, { useState, useEffect, useContext, useRef } from "react";
import SelectCheck from "assets/images/icons/check_icon_yellow.svg";
import ArrowDown from "assets/images/icons/arrow_down_icon_grey.svg";
import { css } from "styles/theme-components";
import { media } from "styles/media";
import { styled } from "styles/theme";
import { formattingHHmm } from "../DateUtil";
import Moment from "moment";
import { ScheduleServiceContext } from "../../context/scheduleService";

const BoardSwitchWrap = styled.div`
    display: inline-block;
    position: relative;
`;

interface SwitchProps {
    editable: boolean;
    propsStatus: string;
    selectDate: string;
    params: any;
    row: number;
}

const Switch = styled.div<SwitchProps>`
    // position: relative;
    display: inline-block;
    width: 80px;
    height: 26px;
    padding: 6px 25px 6px 12px;
    font-size: 12px;
    margin-left: 2px;
    border: 1px solid #cacaca;
    border-radius: 60px;
    line-height: 1;
    background: url(${ArrowDown}) center right 12px no-repeat;
    background-size: 14px;
    margin-right: 6px;
    :hover {
        cursor: not-allowed;
    }
    ${({ editable }) =>
        editable === true
            ? css`
                  border: 1px solid #cacaca;
                  background-color: ${({ theme }) => theme.colors.white};
                  :hover {
                      cursor: pointer;
                  }
              `
            : css`
                  border: 1px solid #eee;
                  background-color: #eee;
                  color: #666;
                  pointer-events: none;
                  :hover {
                      cursor: not-allowed;
                  }
              `};

    :focus {
        outline: none;
        border: 1px solid ${({ theme }) => theme.colors.yellow};
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    }
    :-webkit-autofill,
    :-webkit-autofill:focus {
        border: 1px solid ${({ theme }) => theme.colors.yellow};
        -webkit-box-shadow: 0 0 0px 1000px #fff inset;
        transition: background-color 5000s ease-in-out 0s;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    }
    option {
        display: none;
    }
    ${media.tablet} {
        option {
            display: none;
        }
    }
`;

interface OptionListProps {
    boardSwitch: string;
    optionListOn: boolean;
}
const OptionList = styled.div<OptionListProps>`
    opacity: ${({ optionListOn }) => (optionListOn === true ? 1 : 0)};
    position: absolute;
    width: 80px;
    height: 68px;
    top: 0.1px;
    left: 2px;
    background: #ffffff;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.18);
    border-radius: 13px;
    display: flex;
    align-items: center;
    z-index: ${({ optionListOn }) => (optionListOn === true ? 10 : -1)};
    li {
        padding: 9px 20px;
        width: 80px;
        :hover {
            background-color: #f7f7f7;
            cursor: pointer;
        }
        background: url(${SelectCheck}) center left 4px no-repeat;
        :nth-child(1) {
            ${({ boardSwitch }) =>
                boardSwitch === "WILL_BOARD"
                    ? `background-image: url(${SelectCheck});
                    
                    `
                    : `background-image: none`};
        }
        :nth-child(2) {
            ${({ boardSwitch }) =>
                boardSwitch === "WILL_BOARD"
                    ? `background-image: none`
                    : `background-image: url(${SelectCheck});
                    `};
        }
    }
`;

export const SelectedTime1: React.FC<SwitchProps> = ({
    // 컴포넌트 이름 변경
    propsStatus,
    selectDate,
    params,
    row
}) => {
    const { scheduleList, setScheduleList } = useContext(ScheduleServiceContext);

    const divref = useRef<any>();

    const [active, setActive] = useState(false); // optionactive로 이름변경
    const [status, setStatus] = useState(propsStatus); // ride 상태
    const [edit, setEdit] = useState(false); // switch edit

    const activeCloseOption = (e: any) => {
        const optionList = e.target.className.indexOf("switchClass");

        if (optionList === -1) {
            setActive(false);
            document.removeEventListener("click", activeCloseOption, false);
        } else {
            if (divref.current !== e.target) {
                setActive(false);
                document.removeEventListener("click", activeCloseOption, false);
            }
        }
    };

    useEffect(() => {
        let today = Moment().format("YYYY-MM-DD"); // 포맷팅한 현재 날짜
        let compareTime = Moment(params.startTime, "HH:mm");
        let convertSelectDate = Moment(selectDate); // 모먼트로 컨버트한 날짜
        let nowTime = Moment(Moment().format("HH:mm"), "HH:mm");
        let formatStartDate = convertSelectDate.format("YYYY-MM-DD"); // 포맷한 컨버트 날짜

        if (Moment(today).isAfter(convertSelectDate)) {
            setEdit(false);
        } else {
            if (today === formatStartDate && nowTime.isAfter(compareTime.add(-30, "minutes"))) {
                setEdit(false); // 비활성화
            } else {
                setEdit(true);
            }
        }
    }, [selectDate, params.startTime]);

    const onClick = (boardSwitch: string) => {
        let result: any = [];

        scheduleList.forEach((schedule: any, index: number) => {
            if (index === row) {
                if (
                    schedule.boardSwitch === boardSwitch &&
                    formattingHHmm(schedule.scheduleTime) !== formattingHHmm(schedule.changeTime) &&
                    formattingHHmm(schedule.changeTime) !== ""
                ) {
                    schedule.changeBoardSwitch = "";
                } else if (
                    schedule.boardSwitch === boardSwitch &&
                    (formattingHHmm(schedule.scheduleTime) === formattingHHmm(schedule.changeTime) ||
                        schedule.changeTime === "")
                ) {
                    // 초기화
                    schedule.changeBusName = "";
                    schedule.changeCode = "";
                    schedule.changeTime = "";
                    schedule.changeBoardSwitch = "";
                } else {
                    schedule.changeBoardSwitch = boardSwitch;
                }

                // 안타요 의 경우 기존 시간으로 변경
                if (boardSwitch === "WONT_BOARD") {
                    schedule.changeBusName = "";
                    schedule.changeCode = "";
                    schedule.changeTime = "";
                }
            }
            result.push(schedule);
        });

        setScheduleList(result);
        setStatus(boardSwitch);
    };

    return (
        <BoardSwitchWrap>
            <Switch
                className="switchClass"
                onClick={e => {
                    e.preventDefault();
                    setActive(true);
                    document.addEventListener("click", activeCloseOption);
                }}
                editable={edit}
                propsStatus={status}
                selectDate={""}
                params={""}
                row={0}
                tabIndex={10} // 포커스를 주기위해 추가
                ref={divref}
            >
                <OptionList boardSwitch={status} optionListOn={active}>
                    <ul className="option-list">
                        <li onClick={() => onClick("WILL_BOARD")}> 타요 </li>
                        <li onClick={() => onClick("WONT_BOARD")}> 안타요 </li>
                    </ul>
                </OptionList>
                {status === "WILL_BOARD" ? "타요" : "안타요"}
            </Switch>
        </BoardSwitchWrap>
    );
};
