import React from "react";
import styled, { css } from "styled-components";
import DropDownIcon from "components/atoms/icons/dropdown.svg";
import DropDownDisabledIcon from "components/atoms/icons/dropdown.svg";

type SelectBoxProps = {
    open?: boolean;
    disabled?: boolean;
    isFilled?: boolean;
    title?: string;
    onClick?: () => void;
};
const SelectBox = ({
    open = false,
    disabled = false,
    isFilled = false,
    title = "",
    onClick,
}: SelectBoxProps) => {
    return (
        <SelectBoxWrap
            open={open}
            onClick={onClick}
            isFilled={isFilled}
            disabled={disabled}
            tabIndex={0}
        >
            <LabelWrap>
                <label>{title}</label>
            </LabelWrap>

            <DropDownIconWrap open={open}>
                <img
                    src={disabled ? DropDownDisabledIcon : DropDownIcon}
                    alt="drop down icon"
                />
            </DropDownIconWrap>
        </SelectBoxWrap>
    );
};
export default SelectBox;

type SelectBoxWrapProps = {
    open: boolean;
    disabled: boolean;
    isFilled: boolean;
    tabIndex: number;
};

const SelectBoxWrap = styled.div<SelectBoxWrapProps>`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 32px;
    padding: 6px 12px;
    border: 1px solid ${({ theme }) => "#EBEBEB"};
    border-radius: 30px;
    background-color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme, isFilled }) => isFilled && "#FFE880"};
    background-color: ${({ theme, disabled }) => disabled && "#F5F5F5"};
    cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};

    background-color: ${({ open }) => open && "#FFFAE5"};

    :hover {
        box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.2);
        border: 1px solid #ffcf00;
        font-weight: 800;
        cursor: pointer;
    }
    :focus {
        ${({ disabled }) =>
            disabled === false &&
            css`
                border: 1px solid ${({ theme }) => "#FFE880"};
            `};
    }
`;

type DropDownIconWrapTypes = { open: boolean };

const DropDownIconWrap = styled.div<DropDownIconWrapTypes>`
    position: absolute;
    right: 6px;
    width: auto;
    height: auto;
    transform: rotate(${({ open }) => (open ? "180deg" : "0deg")});
    transition-duration: 0.3s;
`;

const LabelWrap = styled.div`
    width: 98%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-all;

    > label {
        font-size: 12px;
        font-weight: 700;
        line-height: 20px;
        color: #1e2939;
        font-weight: 400;
    }
`;
