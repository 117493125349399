import React, {
    useState,
    useEffect,
    useRef,
    Dispatch,
    SetStateAction,
} from "react";
import styled from "styled-components";

// molecules
import Select from "components/atoms/selectbox";
import DropdownMenu from "components/atoms/dropdownMenu";
import CheckIcon from "components/atoms/icons/check.svg";

type DataProps = {
    id?: number;
    value?: string;
};

type DropdownProps = {
    options?: any;
    disabled?: boolean;
    width?: string;
    date?: string;
    initialText?: string;
    type?: string;
    selectedBus?: string;
    selectedTime?: string;
    setDispatchIds?: Dispatch<SetStateAction<string | undefined>>;
    setSelectedBus?: Dispatch<SetStateAction<string | undefined>>;
    setSelectedTime?: Dispatch<SetStateAction<string | undefined>>;
};

const SelectDown = ({
    width,
    date,
    options,
    disabled,
    initialText,
    type,
    selectedBus,
    selectedTime,
    setDispatchIds,
    setSelectedBus,
    setSelectedTime,
}: DropdownProps) => {
    const selectListBoxRef = useRef<HTMLUListElement>(null);
    const [open, setOpen] = useState<boolean>(false);

    const [selectedPeriod, setSelectedPeriod] = useState<any>({
        id: 0,
        value: date,
    });

    useEffect(() => {
        if (open === false) {
            return;
        }
        const handleClick = (e: MouseEvent) => {
            setOpen(false);
        };
        window.addEventListener("click", handleClick);

        return () => window.removeEventListener("click", handleClick);
    }, [open]);

    useEffect(() => {
        if (open) {
            (selectListBoxRef?.current as HTMLElement).style.display = "block";
            return;
        }
        const selectBoxTimeout = setTimeout(() => {
            (selectListBoxRef?.current as HTMLElement).style.display = "none";
        }, 200);

        return () => clearTimeout(selectBoxTimeout);
    }, [open]);

    const handleSelect = () => setOpen((prev) => !prev);

    const handleDropdownMenu = (data: any) => {
        if (type === "period") {
            setDispatchIds(data.dispatchIds);
        }
        setSelectedPeriod(data);
        setOpen(false);
    };

    const placeholder = () => {
        if (initialText && selectedPeriod?.value === undefined) {
            return initialText;
        }
        if (
            type === "period" &&
            !initialText &&
            selectedPeriod?.value === undefined
        ) {
            return options[0]?.value;
        }
        if (
            type === "period" &&
            !initialText &&
            selectedPeriod?.value !== undefined
        ) {
            return selectedPeriod?.value;
        }
        if (initialText && selectedPeriod?.value !== undefined) {
            return selectedPeriod?.value;
        }
        if (
            (initialText &&
                type === "bus" &&
                selectedBus === undefined &&
                selectedPeriod?.value !== undefined) ||
            (initialText &&
                type === "time" &&
                selectedTime === undefined &&
                selectedPeriod?.value !== undefined)
        ) {
            return "전체";
        }
    };

    useEffect(() => {
        if (type === "bus") {
            setSelectedBus(selectedPeriod?.value);
        } else if (type === "time") {
            setSelectedTime(selectedPeriod?.value);
        }
    }, [selectedPeriod?.value]);

    const totalButtonClick = () => {
        if (type === "bus") {
            setSelectedBus(undefined);
            setSelectedPeriod({});
        } else if (type === "time") {
            setSelectedTime(undefined);
            setSelectedPeriod({});
        }
    };

    return (
        <DropdownContainer width={width}>
            <Select
                disabled={disabled}
                open={open}
                title={disabled ? "전체" : placeholder()}
                onClick={disabled ? () => {} : handleSelect}
            />
            <DropdownMenuContainer
                aria-label={"dropdown-ul"}
                ref={selectListBoxRef}
                open={open}
                initialText={initialText}
            >
                {type !== "period" && (
                    <CheckImg onClick={totalButtonClick}>
                        <CheckedWrap>
                            {selectedPeriod?.value === undefined && (
                                <img src={CheckIcon} alt="" />
                            )}
                        </CheckedWrap>
                        <TotalButton key="-1">전체</TotalButton>
                    </CheckImg>
                )}
                {options?.map((item: DataProps) => {
                    return (
                        <DropdownMenu
                            key={item?.id}
                            isSelected={item?.id === selectedPeriod?.id}
                            onClick={() => handleDropdownMenu(item)}
                            checked={selectedPeriod.value}
                        >
                            {item?.value}
                        </DropdownMenu>
                    );
                })}
            </DropdownMenuContainer>
        </DropdownContainer>
    );
};
export default SelectDown;

type DropdownContainerProps = {
    width?: string;
};

const DropdownContainer = styled.div<DropdownContainerProps>`
    position: relative;
    width: ${({ width }) => width || "100%"};
    height: 100%;

    ::-webkit-scrollbar {
        display: none; /* 크롬, 사파리, 오페라, 엣지 */
    }
`;

type DropdownMenuContainerProps = {
    open: boolean;
    initialText?: string;
};

const DropdownMenuContainer = styled.ul<DropdownMenuContainerProps>`
    z-index: 100;
    position: absolute;
    top: 100%;
    left: 0;
    display: none;
    width: 100%;
    overflow: scroll;
    height: ${(props) => (props.initialText ? "1" : "220px")};
    padding: ${(props) => (props.initialText ? "0px" : "4px 0px")};
    margin-top: 2px;
    border-radius: 12px;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.2);

    & > li:not(:last-child) {
        margin-bottom: 8px;
    }

    @keyframes fade-in {
        to {
            max-height: 100vh;
        }
    }
    @keyframes fade-out {
        to {
            opacity: 0;
        }
    }

    animation: ${({ open }) =>
        open ? "fade-in 0.4s ease-in-out" : "fade-out 0.4s ease"};
    animation-fill-mode: forwards;

    ::-webkit-scrollbar {
        display: none; /* 크롬, 사파리, 오페라, 엣지 */
    }
`;

const TotalButton = styled.div`
    font-weight: 400;
    font-size: 12px;
    color: #1e2939;
`;

const CheckImg = styled.div`
    margin-top: 4px;
    display: flex;
    align-items: center;
    height: 32px;
    padding: 8px 20px 8px 4px;
    :hover {
        background-color: #f7f7f7;
        cursor: pointer;
    }
`;

const CheckedWrap = styled.div`
    flex-shrink: 0;
    width: 12px;
    height: 12px;
    margin-right: 6px;
`;
