import React, {
    useEffect,
    useState,
    Dispatch,
    SetStateAction,
    useRef,
} from "react";
import styled from "styled-components";
import Span from "components/atoms/span/index";
import moment from "moment";
import DatePicker from "react-datepicker";
import CalendarLeftArrow from "assets/images/icons/calendar_left_arrow.svg";
import CalendarRightArrow from "assets/images/icons/calendar_right_arrow.svg";
import { Icon } from "styles/ui";
import {
    Calendar,
    CalendarDisabledLeft,
    CalendarDisabledRight,
} from "assets/images";
import "./rangeDatePicker.css";

type DatePickerProps = {
    getApi?: () => void;
    startDate: Date;
    setStartDate?: Dispatch<SetStateAction<Date>>;
    endDate: Date | null;
    setEndDate?: Dispatch<SetStateAction<Date>>;
    selected?: any;
    selectedDay?: number;
    maxDate?: string;
    setWeekCnt?: Dispatch<SetStateAction<Number>>;
};

function RangeDatePicker(props: DatePickerProps) {
    const {
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        selectedDay,
        maxDate,
        setWeekCnt,
    } = props;
    const [firstDateSelected, setFirstDateSelected] = useState<any>(null);
    const [lastDateSelected, setLastDateSelected] = useState<Date>(null);
    const calRef = useRef<any>();
    const childRef = useRef();

    const today = new Date();
    let tomorrow = new Date(today);
    tomorrow.setDate(today.getDate());

    // 날짜 선택시
    const dateChange = (dates: any) => {
        const [start, end] = dates;
        setStartDate(start);
        setFirstDateSelected(start);
        setEndDate(end);
        setLastDateSelected(end);
    };

    const filterDateFunction = (date: Date) => {
        return Number(date.getDay()) === Number(selectedDay);
    };

    const filteredDates = [];
    for (let i = 0; i < 365; i++) {
        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() + i);
        if (filterDateFunction(currentDate)) {
            filteredDates.push(currentDate);
            if (filteredDates.length > 2) {
                break;
            }
        }
    }

    useEffect(() => {
        if (startDate === undefined) {
            setStartDate(filteredDates[0]);
        } else if (
            moment(startDate).format("yyyy-MM-DD") ===
            moment(new Date()).format("yyyy-MM-DD")
        ) {
            setStartDate(filteredDates[1]);
        } else if (endDate === undefined) {
            setEndDate(filteredDates[0]);
        } else if (
            moment(endDate).format("yyyy-MM-DD") ===
            moment(new Date()).format("yyyy-MM-DD")
        ) {
            setEndDate(filteredDates[1]);
        }
    }, [startDate, endDate]);

    useEffect(() => {
        if (lastDateSelected !== null) {
            calRef.current.setOpen(false);
        }
    }, [lastDateSelected]);

    const handleMonthChange = (date: Date) => {
        function getWeeksInMonth(year, month) {
            const firstDay = new Date(year, month, 1);
            const lastDay = new Date(year, month + 1, 0);

            const firstDayOfWeek = firstDay.getDay();
            const lastDate = lastDay.getDate();

            const totalWeeks = Math.ceil((firstDayOfWeek + lastDate) / 7);

            return totalWeeks;
        }

        const year = date.getFullYear();
        const month = date.getMonth();

        const week = getWeeksInMonth(year, month);
        setWeekCnt(week);
    };

    return (
        <DatePicker
            locale="ko"
            filterDate={(date) => {
                return Number(date.getDay()) === Number(selectedDay);
            }}
            startDate={startDate}
            endDate={endDate}
            ref={calRef}
            onChange={dateChange}
            selectsRange={true}
            open={true}
            minDate={tomorrow}
            maxDate={new Date(maxDate)}
            showPopperArrow={false}
            dateFormat="yyyy-MM-dd"
            shouldCloseOnSelect={false}
            className="rangeDatePicker"
            customInput={<CustomInput ref={childRef} />}
            popperPlacement="top-end"
            onMonthChange={handleMonthChange}
            renderCustomHeader={({
                date,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
            }) => {
                return (
                    <>
                        <div>
                            <div
                                style={{
                                    width: "256px",
                                    maxHeight: "80px",
                                    display: "flex",
                                    padding: "16px",
                                    fontFamily: "NanumSquareRound",
                                }}
                            >
                                <div
                                    style={{
                                        textAlign: "left",
                                        marginRight: 8,
                                    }}
                                >
                                    <Span
                                        style={{
                                            fontSize: "11px",
                                            color: "#585858",
                                            fontWeight: 400,
                                        }}
                                    >
                                        시작일
                                    </Span>
                                    <StartDateInput
                                        selected={firstDateSelected}
                                    >
                                        {moment(startDate).format("YYYY-MM-DD")}
                                    </StartDateInput>
                                </div>
                                <div
                                    style={{
                                        textAlign: "left",
                                    }}
                                >
                                    <Span
                                        style={{
                                            fontSize: "11px",
                                            color: "#585858",
                                            fontWeight: 400,
                                        }}
                                    >
                                        종료일
                                    </Span>
                                    <EndDateInput
                                        endDate={endDate}
                                        selected={firstDateSelected}
                                    >
                                        {endDate === null
                                            ? "날짜를 선택하세요."
                                            : moment(endDate).format(
                                                  "YYYY-MM-DD"
                                              )}
                                    </EndDateInput>
                                </div>
                            </div>
                            <div
                                style={{
                                    width: "224px",
                                    height: "1px",
                                    backgroundColor: "#f0f0f0",
                                    margin: "0 auto",
                                }}
                            />
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    textAlign: "left",
                                    padding: 16,
                                    maxHeight: "52px",
                                }}
                            >
                                <ChangeMonthButton
                                    disabled={prevMonthButtonDisabled}
                                    onClick={decreaseMonth}
                                    style={{ marginRight: 8 }}
                                >
                                    <Icon
                                        img={
                                            moment(new Date()).format(
                                                "YYYY-MM"
                                            ) === moment(date).format("YYYY-MM")
                                                ? CalendarDisabledLeft
                                                : CalendarLeftArrow
                                        }
                                        width={"100%"}
                                        height={"100%"}
                                    ></Icon>
                                </ChangeMonthButton>
                                <CurrentMonth>
                                    {moment(date).format("YYYY")}년{" "}
                                    {moment(date).format("MM")}월
                                </CurrentMonth>
                                <ChangeMonthButton
                                    disabled={nextMonthButtonDisabled}
                                    onClick={increaseMonth}
                                >
                                    <Icon
                                        img={
                                            moment(maxDate).format(
                                                "YYYY-MM"
                                            ) === moment(date).format("YYYY-MM")
                                                ? CalendarDisabledRight
                                                : CalendarRightArrow
                                        }
                                        width={"100%"}
                                        height={"100%"}
                                    ></Icon>
                                </ChangeMonthButton>
                            </div>
                        </div>
                    </>
                );
            }}
        ></DatePicker>
    );
}

export default RangeDatePicker;

type CustomInputProps = { value?: string; onClick?: () => void };

const CustomInput = React.forwardRef((props: CustomInputProps, ref: any) => {
    return <div></div>;
});

type InputProps = {
    selected: string;
    endDate?: any;
};

const StartDateInput = styled.div<InputProps>`
    font-size: 13px;
    color: #000;
    line-height: 16px;
    padding: 7px 0 7px 12px;
    border-radius: 4px;
    border: ${(props) =>
        props?.selected === null ? "1px solid #ffcd00" : "1px solid #cecece"};
    margin-top: 2px;
    width: 108px;
    font-weight: 600;
`;

const EndDateInput = styled.div<InputProps>`
    font-size: ${(props) => (props?.endDate === null ? "12px" : "13px")};
    color: #000;
    line-height: 16px;
    border: ${(props) =>
        props?.selected !== null ? "1px solid #ffcd00" : "1px solid #cecece"};
    padding: ${(props) =>
        props?.endDate === null ? "7px 0 7px 8px" : "7px 0 7px 12px"};
    border-radius: 4px;
    margin-top: 2px;
    width: 108px;
    font-weight: 600;
`;

const ChangeMonthButton = styled.button`
    border: none;
    background-color: #fff;
    cursor: pointer;
    width: 16px;
    height: 16px;
    padding: 0px;
    margin-right: 8px;
`;

const CurrentMonth = styled.span`
    font-size: 14px;
    font-weight: 700;
    font-family: NanumSquareRound;
    letter-spacing: -0.42px;
`;
