import React, { useContext, useState, Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import { getUserInfo } from "accessToken";
import styled, { css } from "styled-components";
import { AuthContext } from "context/auth";
import { media } from "styles/media";
import { Icon, Button } from "styles/ui";
// images
import { ReactComponent as YellowBusLogoImg } from "assets/images/common/yellowbus_logo.svg";
import AccountMenuIcon from "assets/images/icons/account_menu_icon.svg";
import Triangle from "assets/images/common/triangle.svg";
import LogOutIcon from "assets/images/icons/logout_icon.svg";
import PlusIcon from "assets/images/icons/plus_icon.svg";
import MasterAccountIcon from "components/atoms/icons/masterAccount.svg";

import { Search } from "./Search";
import { PopUp } from "./PopUp";
import { RegisterStudent } from "components/students/RegisterStudent";
import { GlobalContext } from "context/global";
import { RegisterStudentContext } from "context/registerStudent";
import { Toast } from "./Toast";

// import DropdownMenu from "components/atoms/dropdownMenu"
import Dropdown from "components/molecules/dropdown";

interface HeaderProps {}

export const Header: React.FC<HeaderProps> = () => {
    const [data, setData] = useState<dataInterface>({});
    const { loggedIn } = useContext(AuthContext);
    const {
        registerStudentPopUp,
        setRegisterStudentPopUp,
        isViewStudentRegistrationButton,
        toast,
        setToast,
    } = useContext(GlobalContext);
    const [masterSelectBoxAcademies, setMasterSelectBoxAcademies] = useState<
        { id: number; name: string }[]
    >([]);

    const [accountMenuToggle, setAccountMenuToggle] = useState(false);
    const [registerStudentState, setRegisterStudentState] = useState({
        hasBackButton: false,
        hasParent: false,
        parentCode: undefined,
        step1: true,
        step2: false,
        parentName: "",
        parentPhone: "",
    });

    const toggleAccountMenu = () => {
        return accountMenuToggle === true ? false : true;
    };

    const onClickLogout = async (e: React.MouseEvent) => {
        await localStorage.removeItem("accessToken");
        await localStorage.removeItem("id");
        await localStorage.removeItem("info");
        await localStorage.removeItem("userInfo");
        await localStorage.removeItem("academyId");
        await localStorage.removeItem("academyInfo");
        await localStorage.removeItem("isMaster");
        await localStorage.removeItem("academies");
        window.location.href = "/login";
    };

    // useEffect(() => {
    //     console.log(Number(localStorage.getItem("isMaster")))
    //     if(Number(localStorage.getItem("isMaster")) === 1){
    //         console.log(localStorage.getItem("academies"))
    //         setMasterSelectBoxAcademies(JSON.parse(localStorage.getItem("academies") ?? "[]"));
    //     }
    // }, [])

    useEffect(() => {
        const userInfo = getUserInfo() as dataInterface;
        const title = userInfo?.academy?.name || "Master";

        if (userInfo) {
            setData(userInfo);
        }

        document.title = `${title} - 관리자페이지`;
    }, [loggedIn]);

    useEffect(() => {
        if (toast.toggle) {
            setTimeout(() => {
                setToast({
                    toggle: false,
                    toastStatus: toast.toastStatus,
                    message: toast.message,
                });
            }, 2000);
        }
    }, [toast, setToast]);

    // popup 닫혔을 떄, 학생 컨텍스트 초기화
    useEffect(() => {
        if (!registerStudentPopUp) {
            setRegisterStudentState({
                hasBackButton: false,
                hasParent: false,
                parentCode: undefined,
                step1: true,
                step2: false,
                parentName: "",
                parentPhone: "",
            });
        }
    }, [registerStudentPopUp]);

    return (
        <Fragment>
            <HeaderInner>
                <Link to="/">
                    <YellowBusLogo />
                </Link>
                {isViewStudentRegistrationButton &&
                    Number(localStorage.getItem("isMaster")) === 1 && (
                        <DropdownWrap>
                            <Dropdown />
                        </DropdownWrap>
                    )}
                {isViewStudentRegistrationButton && (
                    <AddStudentButton
                        onClick={() => setRegisterStudentPopUp(true)}
                    >
                        <span>학생등록</span>
                        <Icon img={PlusIcon} width={"12px"} height={"12px"} />
                    </AddStudentButton>
                )}

                {Number(localStorage.getItem("isMaster")) === 1 ? (
                    <MasterAccountButton
                        type="button"
                        onClick={() => setAccountMenuToggle(toggleAccountMenu)}
                    >
                        <img
                            src={MasterAccountIcon}
                            alt="Master Account Button"
                        />
                    </MasterAccountButton>
                ) : (
                    <AccountButton
                        onClick={() => setAccountMenuToggle(toggleAccountMenu)}
                    />
                )}

                <AccountMenu toggle={accountMenuToggle}>
                    {data && (
                        <div>
                            <AcademyName>
                                {data.academy?.name === ""
                                    ? "Master"
                                    : data.academy?.name}
                            </AcademyName>
                            <ul>
                                <li>
                                    <AccountMenuButton onClick={onClickLogout}>
                                        <AccountMenuButtonIcon
                                            img={LogOutIcon}
                                        ></AccountMenuButtonIcon>{" "}
                                        <span>로그아웃</span>
                                    </AccountMenuButton>
                                </li>
                            </ul>
                        </div>
                    )}
                </AccountMenu>
            </HeaderInner>
            <RegisterStudentContext.Provider
                value={{ registerStudentState, setRegisterStudentState }}
            >
                {registerStudentPopUp && (
                    <PopUp
                        id={registerStudentPopUp}
                        handler={setRegisterStudentPopUp}
                        title={"학생등록"}
                        contents={<RegisterStudent />}
                        hasBackButton={registerStudentState.hasBackButton}
                    />
                )}
            </RegisterStudentContext.Provider>
            <Toast
                toggle={toast.toggle}
                toastStatus={toast.toastStatus}
                message={toast.message}
            />
        </Fragment>
    );
};

const HeaderInner = styled.header`
    z-index: 10000;

    position: fixed;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    width: 100%;
    padding: 0 20px;
    height: 52px;

    background-color: #f7f7f7;
    ${media.tablet_} {
        height: auto;
        right: 0;
        width: 100%;
        padding: 40px 20px 20px;
        background-color: #f7f7f7;
    }
`;

const YellowBusLogo = styled(YellowBusLogoImg)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    ${media.tablet_} {
        display: none;
    }
`;

const AccountButtonAcademyName = styled.div`
    font-weight: 800;
    margin-left: 8px;
    display: flex;
    align-items: center;
    span {
        margin-right: 8px;
    }
`;

const AccountButton = styled.div`
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    padding: 12px 20px;
    cursor: pointer;
    width: 62px;
    height: 52px;
    background: url(${AccountMenuIcon}) center center no-repeat;
    background-size: 28px 28px;
    ${AccountButtonAcademyName} {
        display: none;
        color: ${({ theme }) => theme.colors.grey};
    }
    ${media.tablet_} {
        width: 40px;
        background-size: 40px 40px;
        position: relative;
        display: flex;
        align-items: center;
        /* border: 1px solid #bababa; */
        /* background: rgba(255, 255, 255, 0.6); */
        border-radius: 5px;
        /* padding: 8px 12px; */
        top: auto;
        transform: none;
        ${AccountButtonAcademyName} {
            display: flex;
        }
    }
`;
const MasterAccountButton = styled.button`
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);

    width: auto;
    height: auto;
    padding: 0;

    border: none;
    background-size: 28px 28px;
    background-color: #f7f7f7;
    cursor: pointer;

    ${AccountButtonAcademyName} {
        display: none;
        color: ${({ theme }) => theme.colors.grey};
    }
    ${media.tablet_} {
        position: relative;
        display: flex;
        align-items: center;
        top: auto;

        width: auto;
        height: auto;

        transform: none;
        border-radius: 5px;
        background-size: 40px 40px;

        ${AccountButtonAcademyName} {
            display: flex;
        }
    }
`;
interface AccountMenuProps {
    toggle: boolean;
}

const AccountMenu = styled.div<AccountMenuProps>`
    position: absolute;
    display: none;
    padding: 13px;
    -webkit-box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    transition: 0.3s ease-in-out;
    background-color: ${({ theme }) => theme.colors.white};
    z-index: 10;
    ${({ toggle }) =>
        toggle &&
        css`
            display: block;
            top: calc(50% + 16px);
            ${media.tablet_} {
                right: 14px;
                top: calc(50% + 34px);
            }
        `};
    :before {
        content: "";
        display: block;
        position: absolute;
        top: -8px;
        right: 4px;
        width: 12px;
        height: 8px;
        background: url(${Triangle}) center center no-repeat;
        ${media.tablet_} {
            right: 20px;
        }
    }
`;

const AcademyName = styled.div`
    border-bottom: 1px solid #e1e1e1;
    width: 100%;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    padding: 12px 6px;
    margin-bottom: 12px;
`;

const AccountMenuButton = styled.div`
    display: flex;
    align-items: center;
    padding: 7px;
    font-size: 12px;
    cursor: pointer;
    :hover {
        text-decoration: underline;
    }
`;

const AccountMenuButtonIcon = styled(Icon)`
    width: 16px;
    height: 16px;
    display: inline-block;
    margin-right: 4px;
`;

const AddStudentButton = styled(Button)`
    display: none;
    font-size: 12px;
    line-height: 12px;
    padding: 0 16px;
    margin-right: 20px;
    span {
        margin-right: 5px;
    }
    ${media.tablet_} {
        display: flex;
    }
`;

const SearchWrapper = styled.div`
    display: none;
    position: absolute;
    margin-right: 20px;
    width: 100%;
    top: 6px;
    right: 171px;
    width: 100%;
    ${media.tablet_} {
        top: 46px;
        display: block;
        max-width: 262px;
    }
    ${media.desktop} {
        max-width: 500px;
    }
`;

const DropdownWrap = styled.div`
    display: none;
    width: 210px;
    margin-right: 12px;

    ${media.tablet_} {
        display: block;
    }
`;

interface dataInterface {
    academy?: {
        id?: number;
        name?: string;
    };
    id?: number;
    name?: string;
    email?: string;
    phone?: string;
    role?: string;
    enrolledAt?: string;
}
