import React, { useContext, useEffect, Fragment, useState } from "react";
import { DragDropContext, DropResult, DroppableId } from "react-beautiful-dnd";

import styled, { css } from "styled-components";
import { BusServiceContext } from "context/busService";
import { Station } from "./Station";
import { Summary } from "./Summary";
import { LayerMap } from "./LayerMap";
import { media } from "styles/media";
import BusRequest from "http/bus";
import { Box } from "styles/layout";
import { GlobalContext } from "../../context/global";
import { PopUp2 } from "../layout/PopUp2";
import { ConfirmEditSchedule } from "./ConfirmEditSchedule";

const busRequest = new BusRequest();

interface BusServiceDetailProps {
    studentId: number | undefined;
}

interface ButtonStatusProps {
    cancel: number;
    available: boolean;
}

const Course = styled.ul`
    width: 100%;
    max-height: calc(100vh - 328px);
    overflow: auto;
    padding: 60px 2rem 30px;
    border-radius: 0 0 30px 30px;
    background-color: ${({ theme }) => theme.colors.white};
    ${media.tablet_} {
        max-height: calc(100vh - 408px);
    }
    position: relative;
`;

const Detail = styled(Box)`
    padding: 0 !important;
`;

interface paramInterface {
    date: string;
    code: string;
}

const Edit = styled.div`
    top: 0;
    width: 100%;
    padding: 16px 20px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #f7f7f7;
    font-size: 12px;
    border-bottom: 1px solid #e3e3e3;
    color: ${({ theme }) => theme.colors.grey};
`;

const Button = styled.div<ButtonStatusProps>`
    display: flex;
    padding: 5px 10px;
    width: 42px;
    height: 24px;
    font-family: NanumSquareRound;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    border-radius: 2px;
    cursor: pointer;
    margin: 2px;

    ${({ cancel }) =>
        cancel === 1
            ? css`
                  background-color: #e9e9e9;
                  color: #000000;
              `
            : css`
                  background-color: #444444;
                  color: #ffffff;
              `};
    ${({ available }) =>
        !available
            ? css`
                  background-color: #eeeeee;
                  color: rgba(102, 102, 102, 1);
                  cursor: not-allowed;
              `
            : ``};
`;
export const BusServiceDetail: React.FC<BusServiceDetailProps> = ({
    studentId,
}) => {
    const {
        date,
        busServiceId,
        dispatchCode,
        schedules,
        setSchedules,
        reloadTrigger,
        selectedDispatchData,
        setEditable,
        scheduleEdit,
        setScheduleEdit,
    } = useContext(BusServiceContext);

    const [chgSchedules, setChgSchedules] = useState<any[]>([]);
    const [timerOn, setTimerOn] = useState(false);
    const { setToast } = useContext(GlobalContext);
    const [popUpParams, setPopUpParams] = useState({});

    const [confirmPopUp, setConfirmPopUp] = useState<boolean>(false);
    const [details, setDetails] = useState([]);

    // 10초 마다 운행리스트 & 스케줄 갱신
    const getData = async () => {
        // 운행리스트
        busRequest.getBusServiceDetailList(
            dispatchCode,
            date,
            (response: any) => {
                if (
                    response.status === -1 ||
                    (response.status === 200 && response.data.code !== "OK")
                ) {
                    console.log(
                        `[${response.data.code}] ${response.data.message}`
                    );
                } else {
                    setDetails(response.data.data);
                }
            },
            (error: any) => {
                setDetails([]);
            }
        );

        // 스케줄
        busRequest.getSchedules(
            dispatchCode,
            date,
            (response: any) => {
                if (
                    response.status === -1 ||
                    (response.status === 200 && response.data.code !== "OK")
                ) {
                    console.log(
                        `[${response.data.code}] ${response.data.message}`
                    );
                } else {
                    setSchedules(response.data.data);
                }
            },
            (error: any) => {
                setSchedules([]);
            }
        );

        setEditable(checkEditable());
    };

    useEffect(() => {
        if (scheduleEdit) return;

        let interval: any = null;
        if (timerOn) {
            interval = setInterval(getData, 10000);
        } else if (!timerOn) {
            clearInterval(interval);
        }

        return () => clearInterval(interval);
    }, [timerOn, scheduleEdit, dispatchCode]);

    useEffect(() => {
        setTimerOn(false);
        setDetails([]);
        setScheduleEdit(false);
        getData().then(() => setTimerOn(true));

        // eslint-disable-next-line
    }, [dispatchCode, reloadTrigger, busServiceId]);

    function scheduleEditable(e: any) {
        const isCancel = e.target.dataset.cancel === "0";
        if (scheduleEdit) {
            if (isCancel) {
                setPopUpParams({
                    title: "취소 확인",
                    msg: "수정을 취소하시겠습니까?",
                    isSave: false,
                });
            } else {
                setPopUpParams({
                    title: "저장 확인",
                    msg: "저장 하시겠습니까?",
                    isSave: true,
                });
            }
            setConfirmPopUp(true);
        } else {
            setScheduleEdit(checkEditable());
        }
    }

    function checkEditable() {
        if (selectedDispatchData) {
            // 미운행일때, 수정 불가
            if (selectedDispatchData.status !== 3) {
                const serviceDate =
                    selectedDispatchData?.serviceDate.split(" ")[0];
                let serviceDateTime: any = `${serviceDate}T${selectedDispatchData?.startTime}`;
                serviceDateTime = new Date(serviceDateTime);
                let now = new Date();
                const notEditableServiceDateTime = new Date(
                    serviceDateTime.setMinutes(
                        serviceDateTime.getMinutes() - 30
                    )
                );

                if (now < notEditableServiceDateTime) {
                    return true;
                } else {
                    return false;
                }
            }
        }
        return false;
    }

    function onDragEnd(result: DropResult): void {
        const { source, destination, draggableId } = result;

        if (!result.destination) return;
        if (source.droppableId === destination?.droppableId) {
            return;
        }

        console.log(result);

        const _schedules = [...schedules];
        const targetInfo = draggableId.split("-");
        let studentId = parseInt(targetInfo[1]);

        let droppableId: DroppableId | undefined = destination?.droppableId;

        let check = _schedules.some(function (info) {
            if (
                typeof droppableId === "string" &&
                studentId === info.student.id
            ) {
                let pickUpStationId = 0;
                let takeOffStationId = 0;
                let pickUpIndex = 0;
                let takeOffIndex = 0;

                let tempId = parseInt(droppableId);
                const stations = details;

                if (targetInfo[0] === "inbound") {
                    pickUpStationId = tempId;
                    stations.forEach((detail: any, index) => {
                        if (pickUpStationId === detail.station.id) {
                            pickUpIndex = index;
                        }
                        if (info.takeOffStation.id === detail.station.id) {
                            takeOffIndex = index;
                        }
                    });
                    if (pickUpIndex >= takeOffIndex) {
                        return false;
                    } else {
                        info.pickUpStation.id = tempId;
                        return true;
                    }
                } else if (targetInfo[0] === "outbound") {
                    takeOffStationId = tempId;
                    stations.forEach((detail: any, index) => {
                        if (info.pickUpStation.id === detail.station.id) {
                            pickUpIndex = index;
                        }
                        if (takeOffStationId === detail.station.id) {
                            takeOffIndex = index;
                        }
                    });
                    if (pickUpIndex >= takeOffIndex) {
                        return false;
                    } else {
                        info.takeOffStation.id = tempId;
                        return true;
                    }
                }
            }
            return false;
        });

        if (check) {
            setSchedules(_schedules);
            setChgSchedules([]);
            setChgSchedules(_schedules);
        } else {
            setToast({
                toggle: true,
                toastStatus: "fail",
                message: `승하차 정류장 위치가 맞지 않습니다.`,
            });
        }
    }

    return (
        <Fragment>
            <LayerMap studentId={Number(studentId)} />

            {busServiceId !== null ? (
                <Detail>
                    <Summary />
                    <Edit>
                        수정은 첫 정류장 출발 30분 전까지만 가능합니다.
                        {Number.isNaN(Number(studentId)) && !scheduleEdit && (
                            <Button
                                cancel={0}
                                available={checkEditable()}
                                onClick={scheduleEditable}
                                data-cancel={0}
                            >
                                수정
                            </Button>
                        )}
                        {Number.isNaN(Number(studentId)) && scheduleEdit && (
                            <div style={{ display: "flex" }}>
                                <Button
                                    cancel={1}
                                    available={true}
                                    onClick={scheduleEditable}
                                    data-cancel={0}
                                >
                                    취소
                                </Button>
                                <Button
                                    cancel={0}
                                    available={true}
                                    onClick={scheduleEditable}
                                    data-cancel={1}
                                >
                                    저장
                                </Button>
                            </div>
                        )}
                    </Edit>
                    <Course>
                        <DragDropContext onDragEnd={onDragEnd}>
                            {details.map((detail: any, index) => {
                                return (
                                    <Station
                                        key={`detail-${index}`}
                                        order={index}
                                        detail={detail}
                                        schedules={schedules}
                                        studentId={studentId}
                                        index={index}
                                        scheduleEdit={scheduleEdit}
                                        isLast={details.length - 1 === index}
                                    ></Station>
                                );
                            })}
                        </DragDropContext>
                    </Course>
                </Detail>
            ) : (
                <div></div>
            )}

            <PopUp2
                open={confirmPopUp}
                handler={setConfirmPopUp}
                contents={
                    <ConfirmEditSchedule
                        params={popUpParams}
                        handler={setConfirmPopUp}
                        chgSchedules={chgSchedules as []}
                    />
                }
            />
        </Fragment>
    );
};
